import URLS from '@/constants/api';
import { post } from "./api";

export const getEventWithTicket = async (id:string | undefined) => {
  const requestData = {
      eventId: id
      };

  const response = await post(URLS.GET_EVENT_TICKETS, requestData);
  return response;
};

