import URLS from '@/constants/api';
import { deleteData} from "./api";

export const deleteEvent = async (
    eventId: string | number,
  ) => {
    const endpoint = `${URLS.DELETE_EVENT}/?eventId=${eventId}`;
    const response = await deleteData(endpoint);
    return response;
  };
  
