import React, { FC } from "react";
import "./Modal.css";
import { CancelIcon } from "@/constants/utils/svgs";
import { ModalProps } from "@/@Interfaces/ExitModalProps";

const GrandModal: FC<ModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay-1">
          <div className="modal-1" onClick={(e) => e.stopPropagation()}>
            <div className="cancel-icon" onClick={onClose}>
              {CancelIcon()}
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default GrandModal;
