import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import { NavigationPaths } from "@/constants/paths";
import { StylesConfig } from "react-select";
import DatePicker from "react-datepicker";
import TimezoneSelect from "react-timezone-select";
import {
  CampaignIcon,
  ExitIcon,
  calenderIcon,
  searchIcon,
  timeIcon,
} from "@/constants/utils/svgs";
import Modal from "../ExitModal";
import InputMask from "react-input-mask";
import Input from "../Input";
import { countries } from "@/constants/countries";
import Button from "../Button";
import SelectWrapper from "../selectWrapper";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import Loader from "../Spinner";
import { CampaignFormData } from "@/@Interfaces/EventStepTwoProps";
import { StepTwoProps } from "@/@Interfaces/EventStepTwoProps";
import axios from "axios";
import { customTimezones } from "@/constants/timezones";
import URLS from "@/constants/api";
import Image from "../Image";

const phoneRegex = /^\+1[-.\s]?\d{3}[-.\s]?\d{3}[-.\s]?\d{4}$/;

const StepsTwo: React.FC<StepTwoProps> = ({
  eventTypeOption,
  loading,
  setSteps,
  setTimeRange,
  rawAddress,
  setRawAddress,
  handleSubmitCampaign,
  validationError,
  setValidationError,
  dateError,
  setDateError,
  setstepTwoFormData,
  stepTwoFormData,
}) => {
  const {
    eventDate,
    endDate,
    eventTime,
    timezone,
    addressLine1,
    addressLine2,
    city,
    country,
    longitudes,
    latitudes,
    postalCode,
    website,
    phoneNo,
    venueImage,
  } = stepTwoFormData;

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [urlError, setUrlError] = useState("");
  const [phoneError, setphoneError] = useState("");

  const [timeError, setTimeError] = useState<string | null>(null);
  const [postError, setPostError] = useState<string>("");
  const [value, setValue] = useState<any | null>(null);
  const API_KEY = process.env.REACT_APP_BASE_API_KEY;
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onImageSelected = (imageType: string, file: File) => {
    console.log(imageType);
  };
  const {
    register,
    formState: { errors },
    setValue: formValues,
    getValues,
  } = useForm<CampaignFormData>({
    mode: "onChange",
  });

  useEffect(() => {
    if (latitudes || longitudes) {
      formValues("coordinates", `${longitudes},${latitudes}`);
    }
  }, [longitudes, latitudes]);

  function countOccurrences(str: any, char: any) {
    // Split the string by the character and get the length of the resulting array minus 1
    return str.split(char).length - 1;
  }

  useEffect(() => {
    if (value && value.label) {
      let address2 = "";
      let splitValue;
      let address = "";
      //extract country, city etc from loacation's value
      setRawAddress(value);

      const formattedAddress = value.label;
      if (
        countOccurrences(formattedAddress, ",") >
        countOccurrences(formattedAddress, "-")
      ) {
        splitValue = ",";
      } else {
        splitValue = "-";
      }
      const addressParts = formattedAddress.split(splitValue);
      const country = (addressParts.pop() || "").trim();
      const city = (addressParts.pop() || "").trim();
      if (addressParts.length > 1) {
        address2 = (addressParts.pop() || "").trim();
      }
      if (addressParts.length > 0) {
        address = addressParts.join(splitValue);
      }
      geocodeByAddress(formattedAddress).then((results) => {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        handleChange(address2, "addressLine2");
        handleChange(lat, "latitudes");
        handleChange(lng, "longitudes");

        fetchTimezone(lat, lng);
      });

      handleChange(country, "country");
      handleChange(city, "city");
      handleChange(address, "addressLine1");
    }
  }, [value]);

  const fetchTimezone = async (lat: any, lng: any) => {
    try {
      const response = await axios.get(URLS.GET_TIMEZONE as string, {
        params: {
          location: `${lat},${lng}`,
          timestamp: Math.floor(Date.now() / 1000),
          key: API_KEY,
        },
      });
      const timezoneId = response.data.timeZoneId;
      const rawOffset = response.data.rawOffset;
      handleChange(
        {
          value: timezoneId,
          label: timezoneId,
          offset: rawOffset,
        },
        "timezone"
      );
    } catch (error) {
      // showToast('Error fetching timezone:', "error");
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTimeRange = event.target.value;

    handleChange(newTimeRange, "eventTime");
    setTimeRange(newTimeRange);
    setValidationError(null);
    if (eventTime && validationError === null) {
      setTimeError("");
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    navigate(NavigationPaths.CAMPAIGNS);
  };

  //next button event
  const handleNext = () => {
    setFormSubmitted(true);
    if (!eventDate) {
      setDateError("Event date required");
      if (!eventTime) {
        setTimeError("Event time required");
        return;
      }
      return;
    }

    setDateError("");
    setTimeError("");
    return;
  };
  const handleBack = () => {
    setSteps(1);
  };

  //render button according to type of event
  const renderButton = () => {
    if (formSubmitted && !eventDate) {
      setDateError("*Event Date required");
    }
    if (eventTypeOption === "Guest List") {
      return (
        <button
          type="submit"
          onClick={handleStepTwoSubmit}
          className="benefit-submit-button"
          disabled={loading}
        >
          {loading === true ? <Loader /> : "FINISH"}
        </button>
      );
    } else {
      return (
        <Button
          title={"NEXT"}
          type={"submit"}
          backgroundColor="white"
          color="black"
          border="none"
          borderRadius="6px"
          padding="10px 48px 11px 48px"
          fontSize="12px"
          fontWeight="600"
          onButtonClick={handleNext}
        />
      );
    }
  };

  //progress bar on type of event
  const renderBar = () => {
    if (eventTypeOption === "Guest List") {
      return (
        <div style={{ position: "relative" }}>
          <div className="mt-8 row stage-bar">
            <div className="col-6 step-1-event"></div>
            <div className="col-6 step-2"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ position: "relative" }}>
          <div className="mt-8 row stage-bar">
            <div className="col-4 step-1-event"></div>
            <div className="col-4 step-2"></div>
            <div className="col-4"></div>
          </div>
        </div>
      );
    }
  };

  //check time validation in HH:MM format
  const validateTimeRange = () => {
    const [startTime, endTime] = eventTime
      .split("-")
      .map((time: string) => time.trim());
    const isStartHourValid = parseInt(startTime.split(":")[0], 10) <= 24;
    const isEndHourValid = parseInt(endTime.split(":")[0], 10) <= 24;
    const isStartMinuteValid = parseInt(startTime.split(":")[1], 10) <= 60;
    const isEndMinuteValid = parseInt(endTime.split(":")[1], 10) <= 60;
    const isStartDateEarlier =
      new Date(`2000-01-01T${startTime}`) < new Date(`2000-01-01T${endTime}`);

    if (!isStartHourValid || !isEndHourValid) {
      setValidationError("Invalid hour");
    } else if (!isStartMinuteValid || !isEndMinuteValid) {
      setValidationError("Invalid minute");
    } else if (!isStartDateEarlier) {
      setValidationError("Start time must be earlier than end time");
    } else {
      setValidationError(null);
    }
  };

  const customStyles: StylesConfig<any, false> = {
    container: (provided) => ({
      ...provided,
      position: "relative",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: state.isFocused ? "none" : "#8E929A",
      boxShadow: state.isFocused ? "0 0 0 1px #BBA383" : "none",
      borderRadius: "6px",
      padding: "5px 0px",
      height: "48px",
      "&:focus": {
        outline: "1px solid #BBA383",
        border: "none !important",
      },
      "&:hover": {
        outline: "none",
        borderColor: "#8E929A",
        boxShadow: "none !important",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#BBA383" : "#1f1f22",
      fontSize: "12px",
      color: state.isSelected ? "white" : "inherit",
      "&:hover": {
        backgroundColor: "#BBA383",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      marginLeft: "25px",
      fontSize: "12px",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "8px !important",
      backgroundColor: "#1f1f22",
      color: "white",
      zIndex: 9999,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      // display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      // display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginLeft: "25px",
    }),
  };

  const customStyles2: StylesConfig<any, false> = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      input: {
        marginLeft: "10px !important",
      },
      backgroundColor: "transparent",
      borderColor: state.isFocused ? "none" : "#8E929A",
      boxShadow: state.isFocused ? "0 0 0 1px #BBA383" : "none",
      borderRadius: "6px",
      padding: "5px 0px",
      height: "48px",
      "&:focus": {
        outline: "1px solid #BBA383",
        border: "none !important",
      },
      "&:hover": {
        outline: "none",
        borderColor: "#8E929A",
        boxShadow: "none !important",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#BBA383" : "#1f1f22",
      fontSize: "12px",
      color: state.isSelected ? "white" : "inherit",
      "&:hover": {
        backgroundColor: "#BBA383",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      marginLeft: "10px",
      fontSize: "12px",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "8px !important",
      backgroundColor: "#1f1f22",
      color: "white",
      zIndex: 9999,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      // display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      // display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginLeft: "10px",
    }),
  };

  //if none of the values are empty, naviagte to step 3
  const handleStepTwoSubmit = (e: any) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (
      eventDate &&
      eventTime &&
      !dateError &&
      !timeError &&
      city &&
      latitudes &&
      longitudes &&
      addressLine1 &&
      validationError === null &&
      timezone &&
      !phoneError
    ) {
      console.log("dads");

      if (eventTypeOption === "Guest List") {
        handleSubmitCampaign();
      } else if (eventTypeOption === "Ticketed") {
        setSteps(3);
      } else {
        console.log("wrong event type");
      }
    } else {
      console.log("wrong event type");
    }
  };

  const handleChange = (value: any, name: string) => {
    setstepTwoFormData((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <section className="event0-details-section w-100">
      <div className="row">
        <div className="col-lg-6 col-md-6 p-0 adjust-height-fhbsh">
          <div className="event-form-section">
            <div className="event-form-header">
              <h2>Event Details</h2>
              <p>
                {eventTypeOption === "Guest List"
                  ? "Step 2 of 2"
                  : "Step 2 of 3"}
              </p>
            </div>
            <form className="form-area" onSubmit={handleStepTwoSubmit}>
              <div className="event-form-wrapper row mb-4">
                <div className="col-6">
                  <label className="form-label">Event Date(s) *</label>
                  <DatePicker
                    showIcon
                    icon={calenderIcon()}
                    toggleCalendarOnIconClick
                    showPopperArrow={true}
                    placeholderText="MM.DD.YY - MM.DD.YY"
                    dateFormat="MM-dd-yy"
                    minDate={new Date()}
                    selectsRange={true}
                    startDate={eventDate}
                    endDate={endDate}
                    onChange={(update: any) => {
                      if (update) {
                        handleChange(update[0], "eventDate");
                        handleChange(update[1], "endDate");

                        setDateError("");
                      } else {
                        setDateError("*Event date required");
                      }
                    }}
                  />
                  {(!eventDate || !endDate) && (
                    <span className="error-color d-block">{dateError}</span>
                  )}
                </div>
                <div className="event-time-wrapper col-6">
                  <label className="form-label">Event Time *</label>
                  <InputMask
                    mask="99:99 - 99:99"
                    className="input-mask"
                    placeholder="00:00 - 00:00"
                    value={eventTime}
                    onChange={handleInputChange}
                    onBlur={validateTimeRange}
                  ></InputMask>
                  <span className="time-icon">{timeIcon()}</span>
                  {formSubmitted && !eventTime && (
                    <div className="my-1 error-text-asd errorField">
                      *Event time required
                    </div>
                  )}
                  {/* {timeError && (
                    <span className="error-color">{timeError}</span>
                  )} */}

                  {validationError && eventTime && (
                    <span className="error-color">{validationError}</span>
                  )}
                </div>
              </div>
              <div className="event-address position-relative row  mb-4">
                <div className="col-12">
                  <label className="form-label">Event Address </label>
                  <span className="search-icon-illusion">{searchIcon()}</span>
                  <GooglePlacesAutocomplete
                    apiKey={API_KEY}
                    selectProps={{
                      placeholder: "Search Address",
                      defaultValue: rawAddress,
                      onChange: (values) => {
                        setValue(values);
                      },

                      value: rawAddress,
                      styles: customStyles,
                      isClearable: true,
                    }}
                  />

                  {formSubmitted && !rawAddress && (
                    <span className="error-color d-block">
                      *this field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="event-form-wrapper row mb-4">
                <div className="address-line-1 col-6">
                  <input
                    type="text"
                    value={addressLine1}
                    name="addressLine1"
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    className="input-value-field w-100"
                    placeholder="Address line one"
                  />
                  {formSubmitted && !addressLine1 && (
                    <span className="error-color d-block">
                      *Address is required
                    </span>
                  )}
                </div>
                <div className="address-line-2 col-6">
                  <input
                    type="text"
                    value={addressLine2}
                    name="addressLine2"
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    className="input-value-field w-100"
                    placeholder="Address line two"
                  />
                </div>
              </div>
              <div className="event-form-wrapper row mb-4">
                <div className="city col-6">
                  <input
                    type="text"
                    value={city}
                    name="city"
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    className="input-value-field w-100"
                    placeholder="City"
                  />
                  {formSubmitted && !city && (
                    <span className="error-color d-block">
                      *City is required
                    </span>
                  )}
                </div>
                <div className="country col-6">
                  <SelectWrapper
                    options={countries}
                    value={country}
                    setValue={(value: any) => handleChange(value, "country")}
                    placeholder="Country"
                  />
                </div>
              </div>
              <div className="event-form-wrapper row">
                <div className="post-code col-6">
                  <input
                    type="text"
                    value={postalCode}
                    name="postalCode"
                    onChange={(e) => {
                      const { name, value } = e.target;

                      if (value.length > 10) {
                        setPostError(
                          "Post or ZIP code cannot exceed 10 characters"
                        );
                      } else {
                        setPostError("");
                      }

                      handleChange(value, name);
                    }}
                    className="input-value-field w-100"
                    placeholder="Post or ZIP Code"
                  />

                  <div className="my-1 error-text-asd errorField">
                    {postError}
                  </div>
                </div>
                <div className="b-longitude longitude col-6">
                  <Input
                    type="text"
                    id="coordinates"
                    text="Longitude, Latitude"
                    padding="10px 12px 11px"
                    placeholder="Longitude, Latitude"
                    value={`${longitudes},${latitudes}`}
                    // error={"required field"}
                    disabled={true}
                    register={register}
                    onChange={(value) => formValues("coordinates", value)}
                    error={
                      formSubmitted && (!longitudes || !longitudes)
                        ? "Latitude and Longitude is required"
                        : ""
                    }
                    backgroundColor="#1f1f22"
                  />
                </div>
              </div>
              <div className="event-form-wrapper row mt-4">
                <div className="col-12 timezone-wrapper">
                  <label className="form-label">Event Timezone </label>
                  <TimezoneSelect
                    value={timezone ? timezone : ""}
                    onChange={(value) => handleChange(value, "timezone")}
                    styles={customStyles2}
                    labelStyle={"offsetHidden"}
                    placeholder="Event Timezone"
                    isClearable={true}
                    timezones={customTimezones}
                  />

                  {formSubmitted && !timezone && (
                    <span className="error-color">
                      *Event timezone required
                    </span>
                  )}
                </div>
              </div>
              <div className="event-form-wrapper row mt-4">
                <div className="post-code col-6">
                  <label className="form-label">Venue Website </label>

                  <input
                    type="text"
                    value={website}
                    name="website"
                    className="input-value-field w-100"
                    placeholder="https://www.example.com"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleChange(value, name);
                      if (value) {
                        const isValidUrl =
                          value.startsWith("http://") ||
                          value.startsWith("https://");
                        if (!isValidUrl) {
                          setUrlError("Please enter a valid URL");
                        } else {
                          setUrlError("");
                        }
                      } else {
                        setUrlError("");
                      }
                    }}
                  />

                  <div className="my-1 error-text-asd errorField">
                    {urlError}
                  </div>
                </div>
                <div className="b-longitude longitude col-6">
                  <label className="form-label">Venue Phone </label>

                  <input
                    type="text"
                    value={phoneNo}
                    name="phoneNo"
                    className="input-value-field w-100"
                    placeholder="Phone number"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleChange(value, name);
                      if (value) {
                        if (!phoneRegex.test(value)) {
                          setphoneError("Invalid phone number");
                        } else {
                          setphoneError("");
                        }
                      } else {
                        setphoneError("");
                      }
                    }}
                  />
                  <div className="my-1 error-text-asd errorField">
                    {phoneError}
                  </div>
                </div>
              </div>

              <div className="event-form-wrapper mt-4">
                <div className="col-6 d-flex flex-column justify-content-start ">
                  <label className="form-label">Venue Image</label>
                  {/* <div className="img-desc-cni2 mb-2">
                    This will be shown as the main image on your offer feed in
                    the app.
                  </div> */}
                  <Image
                    required={false}
                    text="Event Venue Image"
                    formSubmitted={formSubmitted}
                    imageSize={{ width: 300, height: 360 }}
                    imageType="feed"
                    onImageSelected={onImageSelected}
                    value={venueImage}
                    setValue={(venueImage) =>
                      handleChange(venueImage, "venueImage")
                    }
                  />
                </div>
                <div className="col-6 d-flex flex-column justify-content-start "></div>
              </div>

              <div className="button-wrapper">
                <div className="back-btn-area">
                  <Button
                    title={"BACK"}
                    type={"button"}
                    backgroundColor="#1A1A1C"
                    color="white"
                    border="1px solid white"
                    borderRadius="6px"
                    padding="10px 48px"
                    fontSize="12px"
                    fontWeight="600"
                    onButtonClick={handleBack}
                  />
                </div>
                {/* Exit Modal*/}
                <div className="next-btn-area">
                  {renderButton()}
                  <Modal
                    showModal={showModal}
                    handleClose={handleCloseModal}
                    handleExit={handleExit}
                    buttonTitle="EXIT"
                    title="Are you sure you want to exit?"
                    message="Are you sure you want to exit creating a new campaign? The information will not be saved."
                  />
                </div>
              </div>
            </form>
          </div>
          <div style={{ position: "relative" }}>{renderBar()}</div>
        </div>
        <div className="col-lg-6 col-md-6 p-0 form-image-right">
          <div className="event-image">
            <div className="overlay-2"></div>
            <span onClick={handleOpenModal} className="exit-icon">
              {ExitIcon()}
            </span>
            <div className="campaign-icon">{CampaignIcon()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepsTwo;
