import React, { useState, useEffect } from "react";
import { ImageProps } from "@/@Interfaces/ImageProps";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setFeedImagePath } from "@/redux/slices/imageSlice";
import { setPageImagePath } from "@/redux/slices/imageSlice";
import { setBusinessImagePath } from "@/redux/slices/businessImageSlice";
import "./style.css";
import { DeleteIcon, ImageIcon } from "@/constants/utils/svgs";
const Image: React.FC<ImageProps> = ({
  text,
  formSubmitted,
  required,
  imageType,
  onImageSelected,
  dropzoneType,
  value,
  setValue,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (imageType === "feed") {
      setSelectedFile(value);
    } else {
      setSelectedFile(value);
    }
  }, [value]);

  useEffect(() => {
    if (formSubmitted && required && !selectedFile) {
      setError(`${text} required`);
    } else if (selectedFile) {
      onImageSelected(imageType, selectedFile);
      setError("");
    }
  }, [required, text, formSubmitted, selectedFile]);

  const handleFileDelete = () => {
    setSelectedFile(null);
    setValue?.(null);
  };

  const validateFileName = (name: string) => {
    const maxLength = 100;
    const validPattern = /^[a-zA-Z0-9\- ()_]+$/;
    if (name.length > maxLength) {
      return `File name should not exceed ${maxLength} characters.`;
    }
    if (!validPattern.test(name)) {
      return "File name should only contain alphabets, numbers, and hyphens.";
    }
    return "";
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    onDrop: async (files) => {
      const file = files[0];
      const fileSizeInKb = file.size / 1024; // Convert bytes to kilobytes
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      const fileNameError = validateFileName(fileNameWithoutExtension);
      if (fileNameError) {
        setError(fileNameError);
        return;
      }
      // Check if image type is 'business' and file size is greater than 50KB(for business)
      if (imageType === "business" && fileSizeInKb > 50) {
        setError("Image size should be 50KB or less for business logo.");
        return;
      }

      const img = document.createElement("img");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        img.onload = () => {
          // if (
          //   img.width === imageSize?.width &&
          //   img.height === imageSize?.height
          // ) {
            setSelectedFile(file);
            const imageObj = { path: reader.result, name: file.name };
         
         console.log(imageObj);
         
            if (imageType === "feed") {
            dispatch(setFeedImagePath(imageObj));
          } else if (imageType === "page") {
            dispatch(setPageImagePath(imageObj));
          } else if (imageType === "business") {
            dispatch(setBusinessImagePath(imageObj));
          }
          // else {
          //   if (
          //     img.width === imageSize?.width &&
          //     img.height === imageSize?.height
          //   ) {
          //     setSelectedFile(file);
          //     if (imageType === "feed") {
          //       dispatch(setFeedImagePath(imageObj));
          //     } else if (imageType === "page") {
          //       dispatch(setPageImagePath(imageObj));
          //     }
          //     setError("");
          //   } else {
          //     setError(
          //       `Image dimensions should be ${imageSize?.width}x${imageSize?.height}`
          //     );
          //   }
          // }
          imageObj !== undefined ? setValue?.(imageObj) : setValue?.("");
          setError("");
          // }
          // else {
          //   setError(
          //     `Image dimensions should be ${imageSize?.width}x${imageSize?.height}`
          //   );
          // }
        };
        img.src = URL.createObjectURL(file);
      };
    },
  });

  return (
    <div className={dropzoneType ? "" : "image-dropzone-wrapper"} tabIndex={0}>
      {selectedFile ? (
        <div className="selected-file" tabIndex={0}>
          <div className="file-info row">
            <span className="input-file-name col-10 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-10">
            {ImageIcon()}{" "}
              {selectedFile.name.length > 25
                ? selectedFile.name.slice(0, 25) + "..."
                : selectedFile.name}
            </span>
            <div
              className=" del-icon col-2 col-md-3 col-lg-3 col-xl-3 col-sm-3 col-xxl-2"
              onClick={handleFileDelete}
            >
              <div>{DeleteIcon()}</div>
            </div>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`img-wrap ${
            imageType === "business" ? "business-image" : ""
          }`}
          tabIndex={0}
        >
          <input {...getInputProps()} />
          <div className="xtra d-flex justify-content-center">
            <h6
              className="header-img text-center"
              style={{ marginTop: dropzoneType ? "0px" : undefined }}
            >
              Drop files here
            </h6>
            <p
              className="content-img"
              style={{ marginBottom: dropzoneType ? "0px" : undefined }}
            >
              Or <span className="click-here">click here</span> to browse your
              files
            </p>
          </div>
        </div>
      )}
      {error && <span className="error-color">*{error}</span>}
    </div>
  );
};

export default Image;
