import React from "react";
import "./style.css";
import PropTypes from "prop-types";
import { ICard } from "@/@Interfaces/ICard";

const Card: React.FC<ICard> = (props) => {
  return (
    <section className="h-100">
      <div className="card">
        <div
          className="card-body d-flex flex-column justify-content-between"
          style={{ cursor: "pointer" }}
        >
          <h5 className="card-title d-flex justify-content-center">
            {props.title}
          </h5>
          <h2 className="title-2 d-flex justify-content-center">
            {props.data}
          </h2>
        </div>
      </div>
    </section>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
};

export default Card;
