import { post } from "./api";
import URLS from "../constants/api";

export const getBenefits = async (
  pageNumber: number,
  pageLimit: number,
  // searchOn: string,
  search: string,
  sortOn: string,
  sortBy: string
) => {
  const requestData = {
    pageNumber,
    pageLimit,
    // ...(searchOn !== "" && { searchOn }),
    ...(search !== "" && { search }),
    ...(sortOn !== "" && { sortOn }),
    ...(sortBy !== "" && { sortBy }),
  };

  const response = await post(URLS.BENEFITS, requestData);
  return response;
};
