import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-data-grid/lib/styles.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { businessTableHeaders } from "@constants/businessTableHeaders";
import { DownloadIcon } from "@constants/utils/svgs";
import { downloadPdfAPI } from "@/services/downloadPdf";
import { BusinessTableRow } from "@/@Interfaces/TableProps";
import { getBusinesses } from "@services/businesses";
import { useSelector } from "react-redux";
import Loader from "../Spinner";
import { options } from "@constants/options";
import { useNavigate } from "react-router-dom";
import TableFooter from "../TableFooter";
import GrandModal from "@/modals/Modal";
import EditBusinessForm from "../EditBusinessForm";
import { downloadBusinessAPI } from "@/services/downloadBusiness";
import TruncateTextWithTooltip from "../Tooltip";
import showToast from "../cogo-toast";
import { getBusinessWithDocs } from "@/services/getBusinessWithDocs";
import { BusinessResponse } from "@/@Interfaces/ViewBusinessWithDocs";

const BusinessesTab: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [refetchToggle, setRefetchToggle] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchOn, setsearchOn] = useState<string>("businessName");
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<BusinessTableRow[]>([]);
  const [selectedRow, setSelectedRow] = useState<BusinessTableRow | null>(null);
  const [businessWithDocs, setBusinessWithDocs] =
    useState<BusinessResponse | null>(null);
  const [sortOn, setSortOn] = useState<string>("businessName");
  const [sortBy, setSortBy] = useState<"asc" | "desc">("asc");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewBusiness, setViewBusiness] = useState(false);

  const dropdownRef2 = useRef<HTMLDivElement>(null);

  const latestDate = new Date();
  const formattedLatestDate = latestDate.toLocaleString();

  const dropdownOption = useSelector(
    (state: any) => state.applicant.dropdownOption
  );

  const pageLimit = options.includes(dropdownOption) ? dropdownOption : "25";

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [dropdownOption]);

  useEffect(() => {
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;

    fetchData(1, pl, "", "", sortBy, sortOn);
  }, [dropdownOption, refetchToggle, sortBy, sortOn]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //fetch page Data on the change of page number
  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;
    fetchData(pageNumber, pl, "", "", sortBy);
  };

  //set previous page number
  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  // get Business data
  const fetchData = async (
    pageNumber = 1,
    pageLimit: number,
    searchOn = "",
    search = "",
    sortBy = "",
    sortOn = ""
  ) => {
    try {
      setShowSpinner(true);
      setTableData([]); //setting table data empty so that loader is shown only instead of showing at the bottom of the table where loader is not visible
      const response = await getBusinesses(
        pageNumber,
        pageLimit,
        searchOn,
        search,
        sortBy,
        sortOn
      );

      setTableData(response.data.businesses.docs);
      setTotalRecords(response.data.businesses.totalDocs);
      setTotalPages(response.data.businesses.totalPages);
    } catch (error: any) {
      setShowSpinner(false);
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast("Something went wrong. ", "error");
      }
    }
    setShowSpinner(false);
  };

  //open Edit Modal
  const handleEditClick = async (rowData: BusinessTableRow) => {
    try {
      const response = await getBusinessWithDocs(rowData._id);
      setBusinessWithDocs(response.data);
    } catch (error) {
      console.log(error);
    }
    setSelectedRow(rowData);
    openModal();
    setViewBusiness(false);
  };

  const handleViewClick = async (rowData: BusinessTableRow) => {
    try {
      const response = await getBusinessWithDocs(rowData._id);
      setBusinessWithDocs(response.data);
    } catch (error) {
      console.log(error);
    }
    setSelectedRow(rowData);
    openModal();
    setViewBusiness(true);
  };

  const handleAddBusiness = () => {
    navigate("/create-business");
  };

  //sorting
  const handleHeaderClick = (headerKey: string) => {
    setCurrentPage(1);
    if (sortOn === headerKey) {
      setSortBy((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortBy("desc");
      setSortOn(headerKey);
    }
  };

  //search business
  const handleSearchBusiness = (value: string) => {
    if (value.length >= 3 || value === "") {
      setCurrentPage(1);
      fetchData(1, pageLimit, searchOn, value, sortBy);
    }
  };
  const handleSubmit2 = (e: React.FormEvent) => {
    e.preventDefault();
  };

  //download business csv file
  const downloadBusinessFile = async () => {
    try {
      setShowSpinner(true);
      const response = await downloadBusinessAPI();
      const url = window.URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      a.download = `PM_Businesses_${formattedLatestDate}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      showToast("Error in downloading file.", "error");
    } finally {
      setShowSpinner(false);
    }
  };

  //download pdf file
  const downloadBusinessPdfFile = async () => {
    try {
      setShowSpinner(true);
      const response = await downloadPdfAPI();
      const url = window.URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Export-${formattedLatestDate}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // console.log("Error fetching data:", error);
    } finally {
      setShowSpinner(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  //Email the user
  const handleEmailClick = (email: string) => {
    const mailtoLink = `mailto:${email}`;
    const anchor = document.createElement("a");
    anchor.href = mailtoLink;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <section className="table-section d-flex flex-column justify-content-between">
      <div className="table-header d-flex flex-1 align-items-center flex-wrap justify-content-between">
        <h4 className="t-head">Businesses</h4>
        <div className="search-bar d-flex flex-wrap gap-3">
          <div>
            <form data-testid="search-form" onSubmit={handleSubmit2}>
              <div className="benefit-search-content">
                <FontAwesomeIcon
                  className="search-icon"
                  icon={faSearch}
                  style={{}}
                />
                <input
                  style={{ height: "40px" }}
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    const { value } = e.target;
                    handleSearchBusiness(value);
                  }}
                />
              </div>
              <button type="submit" style={{ display: "none" }}>
                Search
              </button>
            </form>
          </div>
          <div>
            <button
              style={{
                width: "173px",
                height: "40px",
                borderRadius: "8px",
                border: "none",
                backgroundColor: "#ffffff",
              }}
              onClick={() => handleAddBusiness()}
            >
              <span
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#131313",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                ADD BUSINESS
              </span>
            </button>
          </div>
          <div className="download-icon" onClick={toggleDropdown}>
            {DownloadIcon()}
          </div>
        </div>
      </div>
      {showDropdown && (
        <div id="dropdown" className="csv-dropdown" ref={dropdownRef2}>
          <div onClick={downloadBusinessFile}>Export as .CSV</div>
          {/* <div className="pdf-container">Export as .PDF</div> commenting for future use*/}
        </div>
      )}
      <div className="table-container flex-grow">
        <table
          id="table"
          className="table table-hover"
          style={{ backgroundColor: "#1A1A1C" }}
        >
          <thead className="t-header">
            <tr>
              {businessTableHeaders.map((header) => (
                <th
                  className="t-columns"
                  key={header.key}
                  scope="col"
                  style={header.style}
                  onClick={() => handleHeaderClick(header.key)}
                >
                  {header.name}
                </th>
              ))}
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
            </tr>
          </thead>
          <tbody className="t-body">
            {!showSpinner && tableData.length === 0 && searchTerm.length > 0 ? (
              <td colSpan={businessTableHeaders.length + 1}>
                <div className="no-records-found">
                  No records found.
                  <br />
                  <span
                    className="first-click"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={handleAddBusiness}
                  >
                    Click here
                  </span>
                  <span className="third-line"> to add a Business.</span>
                </div>
              </td>
            ) : !showSpinner &&
              tableData.length === 0 &&
              searchTerm.length === 0 ? (
              <td colSpan={businessTableHeaders.length + 1}>
                <div className="no-records-found">
                  There are currently no businesses.
                  <br />
                  <span
                    className="first-click"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={handleAddBusiness}
                  >
                    Click here
                  </span>
                  <span className="third-line">
                    {" "}
                    to add your first business.
                  </span>
                </div>
              </td>
            ) : (
              <>
                {" "}
                {tableData.map((rowData, index) => (
                  <tr key={index}>
                    {businessTableHeaders.map((header) => (
                      <td
                        style={{
                          verticalAlign: "middle",
                        }}
                        key={header.key}
                      >
                        {header.key === "businessName" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                borderRadius: "50%",
                                overflow: "hidden",
                                width: "40px",
                                height: "40px",
                              }}
                            >
                              <img
                                style={{
                                  borderRadius: "12px",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={
                                  rowData.logo
                                    ? rowData.logo
                                    : "https://uxwing.com/wp-content/themes/uxwing/download/business-professional-services/briefcase-icon.png"
                                }
                                alt=""
                              />
                            </div>

                            <div
                              style={{
                                paddingLeft: "10px",
                                placeSelf: "center",
                              }}
                            >
                              {" "}
                              <TruncateTextWithTooltip
                                text={rowData.businessName}
                              />
                              <span
                                className="email-data truncate-one-line email-hover"
                                onClick={() =>
                                  handleEmailClick(rowData.primaryEmailAddress)
                                }
                              >
                                {rowData.primaryEmailAddress}
                              </span>
                            </div>
                          </div>
                        ) : header.key === "businessCategory" ? (
                          <div className="t-business-category">
                            <span style={{ fontWeight: 700 }}>
                              {rowData.businessCategory}
                            </span>
                          </div>
                        ) : header.key === "businessWebsite" ? (
                          <div className=" truncate-one-line t-business-website">
                            <a
                              className="website-link"
                              href={
                                rowData.businessWebsite.startsWith("http")
                                  ? rowData.businessWebsite
                                  : `http://${rowData.businessWebsite}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>{rowData.businessWebsite}</span>
                            </a>
                          </div>
                        ) : header.key === "phoneNumber" ? (
                          <div className="truncate-one-line-phone t-business-phone">
                            <span>{rowData.accountManager.phoneNumber}</span>
                          </div>
                        ) : header.key === "accountManager" ? (
                          <div>
                            <div className="truncate-one-line account-manager">
                              {rowData.accountManager.firstName +
                                " " +
                                rowData.accountManager.lastName}
                            </div>
                            <span
                              className="email-data truncate-one-line email-hover"
                              onClick={() =>
                                handleEmailClick(rowData.accountManager.email)
                              }
                            >
                              {rowData.accountManager.email}
                            </span>
                          </div>
                        ) : header.key === "city" ? (
                          <div className="truncate-one-line-small b-city">
                            <span>{rowData.city}</span>
                          </div>
                        ) : header.key === "addressLine1" ? (
                          <div className="truncate-two-lines b-address">
                            <a
                              className="click-address"
                              href={`https://www.google.com/maps/search/?api=1&query=${rowData.latitudes},${rowData.longitudes}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="t-address">
                                {rowData.addressLine1}
                              </span>
                            </a>
                          </div>
                        ) : header.key === "instagram" ? (
                          <a
                            className="linkedin-url"
                            href={`https://www.instagram.com/${rowData.instagramHandler}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            <div className="truncate-one-line-small b-instagram">
                              {rowData.instagramHandler}
                            </div>
                          </a>
                        ) : header.key === "linkedin" ? (
                          <a
                            className="linkedin-url"
                            href={rowData.linkedInURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            <div className="truncate-one-line-small b-linkedin">
                              {rowData.linkedInURL}
                            </div>
                          </a>
                        ) : (
                          rowData[header.key as keyof typeof rowData]
                        )}
                      </td>
                    ))}
                    <td>
                      <button
                        className="edit-button "
                        role="button"
                        onClick={() => handleViewClick(rowData)}
                      >
                        View
                      </button>
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      <button
                        className="edit-button "
                        role="button"
                        name="Edit"
                        onClick={() => handleEditClick(rowData)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {showSpinner && (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </div>

      <TableFooter
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        handlePrev={handlePrev}
        handleNext={handleNext}
        totalPages={totalPages}
        currentPage={currentPage}
      />

      {/* Edit Screen Modal */}
      <GrandModal isOpen={isModalOpen} onClose={closeModal}>
        <EditBusinessForm
          refetchToggle={refetchToggle}
          viewBusiness={viewBusiness}
          setRefetchToggle={setRefetchToggle}
          onClose={closeModal}
          selectedBusiness={businessWithDocs}
        ></EditBusinessForm>
      </GrandModal>
    </section>
  );
};

export default BusinessesTab;
