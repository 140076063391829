import { post } from './api';
import { IUpdateApplicant } from '@/@Interfaces/UpdateApplicant';
import URLS from "../constants/api";

export const editApplicantStatus = async (
  email: string,
  membershipStatus: string,
) => {
  const requestData: IUpdateApplicant = {
    email: email,
    membershipStatus: membershipStatus,
  };

  const response = await post(URLS.UPDATE, requestData);
  return response;
};

