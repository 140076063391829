import React from "react";
import "./style.css";
import Button from "@components/Button";
import { useNavigate } from "react-router-dom";
import logo from "@images/logo.svg";
import Footer from "@components/Footer";

const PasswordChanged: React.FC = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/portal");
  };

  return (
    <section className="changed-password-section">
      <div className="changed-content">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-9">
            <div className="changed-display-content">
              <div className="changed-display-header">
                <img src={logo} />
              </div>
              <div className="changed-password-header">
                <h6>Password changed</h6>
              </div>
              <p className="changed-message-text">
                Your password has been successfully changed.
              </p>
              <div className="changed-btn-wrapper">
                <Button
                  title={"LOGIN"}
                  type={"submit"}
                  backgroundColor="#FFFFFF"
                  color="black"
                  border="none"
                  onButtonClick={handleNext}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "5rem" }}> </div>
      </div>
      <Footer></Footer>
    </section>
  );
};

export default PasswordChanged;
