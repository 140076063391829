import { post } from "./api";
import URLS from "../constants/api";

export const getCampaigns = async (
  pageNumber: number,
  pageLimit: number,
  search: string,
  sortOn: string,
  sortBy: string
) => {
  // Create the requestData object with all parameters
  const requestData = {
    pageNumber,
    pageLimit,
    ...(search !== "" && { search }), // Include search if not an empty string
    ...(sortOn !== "" && { sortOn }), // Include sortOn if not an empty string
    ...(sortBy !== "" && { sortBy }), // Include sortBy if not an empty string
  };

  const response = await post(URLS.CAMPAIGNS, requestData);
  return response;
};

export const getRSVPs = async (
  pageNumber: number,
  pageLimit: number,
  searchOn: string,
  search: string,
  sortOn: string,
  sortBy: string,
  eventId: string
) => {
  // Create the requestData object with all parameters
  const requestData = {
    pageNumber,
    pageLimit,
    ...(searchOn !== "" && { searchOn }), // Include searchOn if not an empty string
    ...(search !== "" && { search }), // Include search if not an empty string
    ...(sortOn !== "" && { sortOn }), //
    ...(sortBy !== "" && { sortBy }), //
    ...(eventId !== "" && { eventId }), // Include eventID if not an empty string
  };

  const response = await post(URLS.RSVP_EVENTS, requestData);
  return response;
};
