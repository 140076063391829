import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { CampaignIcon, DashboardIcon, ExitIcon } from "@/constants/utils/svgs";
import showToast from "../cogo-toast";
import { useNavigate } from "react-router";
import Loader from "../Spinner";
import { getUserProfile } from "@/services/getUserProfile";
import { ProfileSettingsProps } from "@/@Interfaces/ProfileSettings";
import { changeProfile } from "@/services/changeProfileSettings";
import Modal from "../ExitModal";

const ProfileSettings: React.FC = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, setLastNameError] = useState("")
  const [emailError, setEmailError] = useState('');
  const [profileData, setProfileData] = useState<ProfileSettingsProps>({
    admin: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetProfile();
  }, []);

  const handleGetProfile = async () => {
    try {
      const response = await getUserProfile();
      setProfileData(response.data);
    } catch (error: any) {
      showToast("Error in fetching data", "error");
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfileData((prevState) => ({
      ...prevState,
      admin: {
        ...prevState.admin,
        email: e.target.value,
      },
    }));
    if (validateEmail(e.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Invalid email address');
    }
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handlephoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNo = e.target.value.replace(/\D/g, '');
    setProfileData((prevState) => ({
      ...prevState,
      admin: {
        ...prevState.admin,
        phoneNo: newPhoneNo,
      },
    }));
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfileData((prevState) => ({
      ...prevState,
      admin: {
        ...prevState.admin,
        firstName: e.target.value,
      },
    }));
    if(e.target.value.length > 50){
      setFirstNameError("First name cannot exceed 50 characters")
    }
    else{
      setFirstNameError("")
    }
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfileData((prevState) => ({
      ...prevState,
      admin: {
        ...prevState.admin,
        lastName: e.target.value,
      },
    }));
    if(e.target.value.length > 50){
      setLastNameError("Last name cannot exceed 50 characters")
    }
    else{
      setLastNameError("")
    }
  };

  const handleChangeSettings = async (e: any) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (
      profileData.admin.firstName &&
      profileData.admin.lastName &&
      profileData.admin.email &&
      profileData.admin.phoneNo && !emailError && !firstNameError && !lastNameError
    ) {
      setLoading(true);
      try {
        const response = await changeProfile(profileData.admin);
        showToast(
          "Your profile settings has been saved successfully!",
          "success"
        );
        navigate("/home/dashboard");
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        showToast(error.response.data.message, "error");
      }
    }
    setLoading(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    navigate("/home/dashboard");
  };

  return (
    <section>
      <div>
        <div className="d-flex gap-2">
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <section className="change-password-section">
            <div className="row">
              <div className="col-lg-6 col-md-9 adjust-height-fhbsh">
                <div className="passsword-form-section ">
                  <div className="form-header">
                    <h2>Profile Settings</h2>
                  </div>
                  <form className="reset-form-parent">
                    <div className="row mb-4">
                      <div className="d-flex flex-column col-6">
                        <label className="form-label">First Name *</label>
                        <input
                          value={profileData?.admin.firstName}
                          onChange={handleFirstNameChange}
                          name="first name"
                          className="input-value-field"
                          placeholder="First Name"
                        />
                        {formSubmitted && !profileData?.admin.firstName && (
                          <div className="my-1 error-text-asd errorField">
                            *First name required
                          </div>
                        )}
                          {firstNameError && profileData?.admin.firstName && (
                          <div className="my-1 error-text-asd errorField">
                           {firstNameError}
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column col-6">
                        <label className="form-label">Last Name *</label>
                        <input
                          value={profileData?.admin.lastName}
                          onChange={handleLastNameChange}
                          name="last name"
                          className="input-value-field"
                          placeholder="Last Name"
                        />
                        {formSubmitted && !profileData?.admin.lastName && (
                          <div className="my-1 error-text-asd errorField">
                            *Last name required
                          </div>
                        )}
                            {lastNameError && profileData?.admin.lastName && (
                          <div className="my-1 error-text-asd errorField">
                           {lastNameError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="d-flex flex-column col-6">
                        <label className="form-label">Email Address *</label>
                        <input
                          value={profileData?.admin.email}
                          type="email"
                          onChange={handleEmailChange}
                          name="email address"
                          className="input-value-field"
                          placeholder="Email Address"
                        />
                        {formSubmitted && !profileData?.admin.email && (
                          <div className="my-1 error-text-asd errorField">
                            *Email address required
                          </div>
                        )}
                            {emailError && profileData?.admin.email && (
                          <div className="my-1 error-text-asd errorField">
                           {emailError}
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column col-6">
                        <label className="form-label">Phone Number *</label>
                        <input
                          value={profileData?.admin.phoneNo}
                          onChange={handlephoneChange}
                          name="phone number"
                          className="input-value-field"
                          placeholder="Phone Number"
                          
                        />
                        {formSubmitted && !profileData?.admin.phoneNo && (
                          <div className="my-1 error-text-asd errorField">
                            *Phone number required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-4">
                      {/* commenting for future use */}
                      {/* <div className="d-flex flex-column col-6">
                        <label className="form-label">Job Title *</label>
                        <input
                          value={jobTitle}
                          onChange={(e) => setJobTitle(e.target.value)}
                          name="event name"
                          className="input-value-field"
                          placeholder="Job Title"
                        />
                        {formSubmitted && !jobTitle && (
                          <div className="my-1 error-text-asd errorField">
                            *Job title required
                          </div>
                        )}
                      </div> */}
                    </div>
                    <div className="row mt-8">
                      <div className="col-6"></div>
                      <div className="col-6">
                        <div className="ticket-finish change-password">
                          <button
                            type="submit"
                            onClick={handleChangeSettings}
                            className="benefit-submit-button"
                          >
                            {loading === true ? <Loader /> : "SUBMIT"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="form-image-right col-lg-6 col-md-3 p-0">
                <div className="image-section">
                  <div className="overlay"></div>
                  <span onClick={handleOpenModal} className="exit-icon">
                    {ExitIcon()}
                  </span>
                  <div className="campaign-icon">{CampaignIcon()}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Modal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleExit={handleExit}
        buttonTitle="EXIT"
        title="Are you sure you want to exit?"
        message="Are you sure you want to exit creating a new event? The information will not be saved."
      />
    </section>
  );
};

export default ProfileSettings;
