// Define the Metric type based on the structure of the performance metrics
interface Metric {
  name: string;
  delta: number;
  id: string;
  startTime: number;
  value: number;
}

// Import the necessary functions from 'web-vitals'
import { getCLS, getFID, getLCP, getFCP } from 'web-vitals';

// Define the type for ReportHandler
type ReportHandler = (metric: Metric) => void;

// Use ReportHandler as needed in your code
const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    // Your code here
  }
};

export default reportWebVitals;
