import React, { useRef, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./style.css";
import { TruncateTextWithTooltipProps } from "@/@Interfaces/TextProps";

const TruncateTextWithTooltip: React.FC<TruncateTextWithTooltipProps> = ({
  text,
}) => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      setIsEllipsisActive(ref.current.offsetWidth < ref.current.scrollWidth);
    }
  }, [text, ref.current]);

  if (!isEllipsisActive) {
    return (
      <div
        ref={ref}
        className="truncate-one-line names-of-event"
        style={{ fontWeight: 700 }}
      >
        {text}
      </div>
      
    );
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          {" "}
          <div style={{ color: "white", fontSize: "12px", fontWeight: "400" }}>
            {text}
          </div>
        </Tooltip>
      }
      data-bs-custom-class="custom-tooltip"
    >
      <div ref={ref} className="truncate-one-line" style={{ fontWeight: 700 }}>
        {text}
      </div>
    </OverlayTrigger>
  );
};

export default TruncateTextWithTooltip;
