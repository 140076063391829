import React, { useState } from "react";
import "./style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormData } from "@/@Interfaces/SignIn";
import Button from "@components/Button";
import logo from "@images/logo.svg";
import Footer from "@components/Footer";
import Input from "@components/Input";
import { forgotPasswordApi } from "@/services/forgotPassword";

const ForgotPassword: React.FC = () => {
  const [email1, setEmail1] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: formValues,
  } = useForm<FormData>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    try {
      const response = await forgotPasswordApi(data.email);
      setShowMessage(true);
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Error during authentication";
      setShowMessage(true);
    }
  };

  const handleEmailChange = (value: string) => {
    setEmail1(value);
  };

  const handleNext = () => {
    formValues("email", email1);
  };

  return (
    <section className="forgot-password-section">
      <div className="fp-form-content">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-9">
            <div className="fp-content">
              <div className="fp-header">
                <img src={logo} />
              </div>
              {showMessage ? (
                <div className="message-screen">
                  <div className="forgot-password-header-2">
                    <h6>Email Sent</h6>
                  </div>
                  <p className="email-message-text">
                    We sent an email to {email1} with a link to reset your
                    password.
                  </p>
                </div>
              ) : (
                <div>
                  {" "}
                  <div className="forgot-password-header">
                    <h6>Forgot Password?</h6>
                  </div>
                  <div style={{ backgroundColor: " #1a1a1c" }}>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="fp-form-parent"
                    >
                      <div className="fp-form">
                        <Input
                          type="text"
                          id="email"
                          placeholder="Enter your email address"
                          register={register}
                          label="Email Address"
                          error={(errors as any).email?.message}
                          value={email1}
                          onChange={handleEmailChange}
                          maxLength={50}
                        />
                      </div>
                      <div className="fp-btn-wrapper">
                        <Button
                          title={"REQUEST A RESET LINK"}
                          type={"submit"}
                          backgroundColor="#FFFFFF"
                          color="black"
                          border="none"
                          onButtonClick={handleNext}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ height: "5rem" }}> </div>
      </div>
      <Footer></Footer>
    </section>
  );
};

export default ForgotPassword;
