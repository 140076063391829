import { post_2 } from './api';
import URLS from "../constants/api";

export const resetPasswordApi = async (
  token:string,
  password: string,
) => {
    const requestData={
        token:token,
        password: password
    }
    
  const response = await post_2(URLS.RESET_PASSWORD, requestData);
  return response;
};

