import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  applicantStatus: 'Pending'
}

const sidebarSlice = createSlice({
  name: 'applicant_status',
  initialState,
  reducers: {
    setApplicantStatus: (state, action) => {
      state.applicantStatus = action.payload
    },
  },
})

export const { setApplicantStatus} = sidebarSlice.actions
export default sidebarSlice.reducer
