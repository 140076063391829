import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-data-grid/lib/styles.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { benefitTableHeaders } from "@/constants/benefitsTableHeaders";
import Loader from "../Spinner";
import { DownloadIcon } from "@constants/utils/svgs";
import { BenefitsTableRow } from "@/@Interfaces/TableProps";
import { getBenefits } from "@services/getBenefits";
import { useSelector } from "react-redux";
import { options } from "@constants/options";
import { useNavigate } from "react-router-dom";
import TableFooter from "../TableFooter";
import { ICreateBenefit } from "@/@Interfaces/CreateTicket";
import { downloadBenefitsAPI } from "@/services/downloadBenefits";
import EditBenefitModal from "../EditBenefits";
import { getIndividualBenefit } from "@/services/getIndividualBenefits";
import showToast from "../cogo-toast";
import GrandModal from "@/modals/Modal";
import {
  getStatusColor,
  getStatusTextColor,
} from "@/constants/utils/convertDate";
import TruncateTextWithTooltip from "../Tooltip";

const BenefitsTab: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchOn, setsearchOn] = useState<string>("name");
  const [tableData, setTableData] = useState<BenefitsTableRow[]>([]);
  const [editTableData, setEditTableData] = useState<ICreateBenefit[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [sortOn, setSortOn] = useState<string>("name");
  const [sortBy, setSortBy] = useState<"asc" | "desc">("asc");
  const [viewBenefit, setViewBenefit] = useState(false);

  const dropdownRef2 = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const latestDate = new Date();
  const formattedLatestDate = latestDate.toLocaleString();

  //get data on edit button's click
  const handleEditBeneiftClick = async (rowData: BenefitsTableRow) => {
    const response = await getIndividualBenefit(rowData._id);
    setEditTableData(response.data);
    setIsEditModalOpen(true);
    setViewBenefit(false);
  };

  const handleViewBenefitClick = async (rowData: BenefitsTableRow) => {
    const response = await getIndividualBenefit(rowData._id);
    setEditTableData(response.data);
    setIsEditModalOpen(true);
    setViewBenefit(true);
  };

  const dropdownOption = useSelector(
    (state: any) => state.applicant.dropdownOption
  );

  const pageLimit = options.includes(dropdownOption) ? dropdownOption : "25";

  const applicantStatus = useSelector(
    (state: any) => state.applicant_status.applicantStatus
  );

  //default page
  useEffect(() => {
    setCurrentPage(1);
  }, [dropdownOption]);

  useEffect(() => {
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;

    fetchData(1, pl, "", sortOn, sortBy);
  }, [applicantStatus, dropdownOption, sortOn, sortBy]);

  //clsoing dropdown menu when click outisde
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //fetch Data on page change
  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;
    fetchData(pageNumber, pl);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  function convertDateFormat(inputDate: string | undefined): string {
    if (!inputDate) {
      return ""; // Handle the case where inputDate is undefined
    }
    const dateObject = new Date(inputDate);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is 0-indexed, so we add 1
    const year = dateObject.getFullYear(); // Keeping the full 4-digit year

    const formattedDate = `${month < 10 ? "0" : ""}${month}/${
      day < 10 ? "0" : ""
    }${day}/${year}`;

    return formattedDate;
  }

  //get Benefit's Table Data
  const fetchData = async (
    pageNumber = 1,
    pageLimit: number,
    search = "",
    sortOn = "",
    sortBy = ""
  ) => {
    try {
      setShowSpinner(true);
      setTableData([]);
      const response = await getBenefits(
        pageNumber,
        pageLimit,
        search,
        sortOn,
        sortBy
      );

      setTableData(response.data.docs);
      setTotalPages(response.data.totalPages);
      setTotalRecords(response.data.totalDocs);
    } catch (error: any) {
      setShowSpinner(false);
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast("Something went wrong. ", "error");
      }
    }
    setShowSpinner(false);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    fetchData(currentPage, pageLimit);
  };

  //navigate to add benefit screen
  const handleAddCampaign = () => {
    navigate("/create-benefit");
  };

  //sorting
  const handleHeaderClick = (headerKey: string) => {
    setCurrentPage(1);
    if (sortOn === headerKey) {
      setSortBy((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortBy("desc");
      setSortOn(headerKey);
    }
  };

  const handleSearchCampaign = (value: string) => {
    if (value.length >= 3 || value === "") {
      setCurrentPage(1);
      fetchData(1, pageLimit, value);
    }
  };

  const handleSubmit2 = (e: React.FormEvent) => {
    e.preventDefault();
  };

  //download benefit csv file
  const downloadEventFile = async () => {
    try {
      setShowSpinner(true);
      const response = await downloadBenefitsAPI();
      const url = window.URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      a.download = `PM_Benefits_${formattedLatestDate}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setShowSpinner(false);
      showToast("Error in downloading file.", "error");
    } finally {
      setShowSpinner(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <section className="table-section d-flex flex-column justify-content-between">
      <div className="table-header d-flex flex-1 align-items-center flex-wrap justify-content-between">
        <h4 className="t-head">Benefits</h4>
        <div className="benefit-search-bar d-flex flex-wrap gap-3">
          <div>
            <form data-testid="search-form" onSubmit={handleSubmit2}>
              <div className="benefit-search-content">
                <FontAwesomeIcon
                  className="benefit-search-icon"
                  icon={faSearch}
                  style={{}}
                />
                <input
                  style={{ height: "40px" }}
                  data-testid="search-input"
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    const { value } = e.target;
                    handleSearchCampaign(value);
                  }}
                />
              </div>
              <button type="submit" style={{ display: "none" }}>
                Search
              </button>
            </form>
          </div>
          <div>
            <button
              className="add-campaign-button"
              style={{
                width: "173px",
                height: "40px",
                borderRadius: "8px",
                border: "none",
                backgroundColor: "#ffffff",
              }}
              onClick={() => handleAddCampaign()}
            >
              <span
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#131313",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                ADD BENEFIT
              </span>
            </button>
          </div>
          <div
            data-testid="download"
            className="download-icon"
            onClick={toggleDropdown}
          >
            {DownloadIcon()}
          </div>
        </div>
      </div>
      {showDropdown && (
        <div
          id="dropdown"
          className="csv-dropdown d-flex flex-column justify-content-center"
          ref={dropdownRef2}
        >
          <div onClick={downloadEventFile}>Export as .CSV</div>
          <div className="pdf-container">Export as .PDF</div>
        </div>
      )}
      <div className="table-container-hd73 flex-grow">
        <table
          data-testid="benefit-table"
          id="table"
          className="table table-hover"
          style={{ backgroundColor: "#1A1A1C" }}
        >
          <thead className="t-header">
            <tr>
              {benefitTableHeaders.map((header: any) => (
                <th
                  className="t-columns"
                  key={header.key}
                  scope="col"
                  style={header.style}
                  onClick={() => handleHeaderClick(header.key)}
                >
                  {header.name}
                </th>
              ))}
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
            </tr>
          </thead>
          <tbody className="t-body">
            {!showSpinner && tableData.length === 0 && searchTerm.length > 0 ? (
              <td colSpan={benefitTableHeaders.length + 1}>
                <div className="no-records-found">
                  No records found.
                  <br />
                  <span
                    className="first-click"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={handleAddCampaign}
                  >
                    Click here
                  </span>
                  <span className="third-line"> to add your Benefit.</span>
                </div>
              </td>
            ) : !showSpinner &&
              tableData.length === 0 &&
              searchTerm.length === 0 ? (
              <td colSpan={benefitTableHeaders.length + 1}>
                <div className="no-records-found">
                  There are currently no Benefits
                  <br />
                  <span
                    className="first-click"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={handleAddCampaign}
                  >
                    Click here
                  </span>
                  <span className="third-line">
                    {" "}
                    to add your first Benefit.
                  </span>
                </div>
              </td>
            ) : (
              <>
                {tableData.map((rowData, index) => (
                  <tr key={index}>
                    {benefitTableHeaders.map((header: any) => (
                      <td
                        style={{
                          verticalAlign: "middle",
                        }}
                        key={header.key}
                      >
                        {header.key === "name" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                borderRadius: "100px",
                                width: "40px",
                                height: "40px",
                              }}
                            >
                              <img
                                style={{
                                  borderRadius: "12px",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={
                                  rowData.feedImage
                                    ? rowData.feedImage
                                    : rowData.pageImage
                                }
                                alt=""
                              />
                            </div>

                            <div
                              style={{
                                width: "calc(100% - 50px)",
                                paddingLeft: "10px",
                                placeSelf: "center",
                                fontWeight: 700,
                                fontSize: "12px",
                              }}
                              className="truncate-two-lines"
                            >
                              <TruncateTextWithTooltip text={rowData.name} />
                            </div>
                          </div>
                        ) : header.key === "description" ? (
                          <div className="truncate-two-lines t-benefit-desc">
                            <span>{rowData.description}</span>
                          </div>
                        ) : header.key === "category" ? (
                          <div className="benefit-table-category">
                            <span>{rowData.category}</span>
                          </div>
                        ) : header.key === "startDate" ? (
                          <div>
                            {rowData.startDate === rowData.endDate ? (
                              <div className="t-date">
                                {rowData.startDate
                                  ? convertDateFormat(rowData.startDate)
                                  : ""}
                              </div>
                            ) : (
                              <div className="t-date">
                                {rowData.startDate
                                  ? convertDateFormat(rowData.startDate)
                                  : ""}{" "}
                                -{" "}
                                {rowData.endDate
                                  ? convertDateFormat(rowData.endDate)
                                  : ""}
                              </div>
                            )}
                            <span>{rowData.startTime}</span>
                          </div>
                        ) : header.key === "addressLine1" ? (
                          <div className="truncate-two-lines benefit-table-address">
                            <a
                              className="click-address"
                              href={`https://www.google.com/maps/search/?api=1&query=${rowData.latitudes},${rowData.longitudes}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="t-address">
                                {" "}
                                {rowData.addressLine1
                                  ? rowData.addressLine1
                                  : "-"}
                              </span>
                            </a>
                          </div>
                        ) : header.key === "status" ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: getStatusColor(rowData.status),
                                borderRadius: "8px",
                                padding: "5px 8px",
                                fontWeight: 700,
                                color: getStatusTextColor(rowData.status),
                                marginBottom: 0,
                              }}
                            >
                              {rowData.status.toUpperCase()}
                            </div>
                          </div>
                        ) : header.key === "type" ? (
                          <div className="benefit-table-type">
                            <span>{rowData.type}</span>
                          </div>
                        ) : header.key === "benefitUrl" ? (
                          <div className="benefit-table-type">
                            <a
                              className="b-tab-url"
                              href={rowData.benefitUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="truncate-two-lines">
                                {rowData.benefitUrl ? rowData.benefitUrl : "-"}
                              </span>
                            </a>
                          </div>
                        ) : (
                          rowData[header.key as keyof typeof rowData]
                        )}
                      </td>
                    ))}
                    <td
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      <button
                        className="edit-button"
                        data-testid="Edit-button"
                        role="button"
                        name="Edit"
                        onClick={() => handleViewBenefitClick(rowData)}
                      >
                        View
                      </button>
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      <button
                        className="edit-button"
                        data-testid="Edit-button"
                        role="button"
                        name="Edit"
                        onClick={() => handleEditBeneiftClick(rowData)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {showSpinner && (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </div>
      <TableFooter
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        handlePrev={handlePrev}
        handleNext={handleNext}
        totalPages={totalPages}
        currentPage={currentPage}
      />
      <GrandModal isOpen={isEditModalOpen} onClose={handleCloseModal}>
        {" "}
        <EditBenefitModal
          handleClose={handleCloseModal}
          viewBenefit={viewBenefit}
          editBenefitData={editTableData}
          data-testid="edit-benefit-modal"
        />
      </GrandModal>
    </section>
  );
};

export default BenefitsTab;
