import React from 'react';
import './style.css';
import {IButtonProps} from '@/@Interfaces/Button'

const Button: React.FC<IButtonProps> = ({
  type,
  title,
  backgroundColor,
  color,
  padding,
  border,
  width,
  fontSize,
  fontWeight,
  borderRadius,
  onButtonClick,
  disabled
}) => {
  const buttonStyle: React.CSSProperties = {
    backgroundColor: backgroundColor || '',
    color: color || '',
    padding: padding || '',
    border: border || '',
    width: width || '',
    fontSize: fontSize || '',
    fontWeight: fontWeight || '',
    borderRadius: borderRadius || '',
  };

  return (
    <button className='admin-btn text-center' type={type} style={buttonStyle} onClick={onButtonClick} disabled={disabled}>
      {title}
    </button>
  );
};

export default Button;
