/* eslint-disable no-debugger */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  feedImage: "",
  pageImage: "",
};

const imageSlice = createSlice({
  name: "image_path",
  initialState,
  reducers: {
    setFeedImagePath: (state, action) => {
      state.feedImage = action.payload;
    },
    setPageImagePath: (state, action) => {
      state.pageImage = action.payload;
    },

    resetImageState: (state) => {
      state.feedImage = initialState.feedImage;
      state.pageImage = initialState.pageImage;
    },
  },
});

export const { setFeedImagePath, setPageImagePath, resetImageState } =
  imageSlice.actions;
export default imageSlice.reducer;
