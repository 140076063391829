import URLS from "@/constants/api";
import axios from "axios";

export const uplaodRecap = async (
  id: string | number,
  fileData: {
    images: any[];
    deleteImages: any;
    oldImages: any;
  }
): Promise<any> => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const formData = new FormData();

    for (const image of fileData.images) {
      formData.append("images", image);
    }

    if (fileData?.deleteImages.length > 0) {
      formData.append("deleteimages", JSON.stringify(fileData?.deleteImages));
    }
    if (fileData?.oldImages.length > 0) {
      formData.append("oldImages", JSON.stringify(fileData?.oldImages));
    }

    const response = await axios.post(URLS.UPLOAD_RECAP(id), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("An error occurred.");
  }
};

export const populateRecap = async (id: string | number): Promise<any> => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const response = await axios.get(URLS.GET_RECAP(id), {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("An error occurred.");
  }
};
