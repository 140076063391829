import URLS from '@/constants/api';
import { post_2 } from "./api";

export const downloadFileAPI = async (applicantStatus: string
) => {
  const requestData = {
        membershipStatus: applicantStatus
      };

  const stringifiedRequestData = JSON.stringify(requestData);

  const response = await post_2(URLS.DOWNLOAD_FILE, stringifiedRequestData);
  return response;
};

