import React, { useState, useEffect } from "react";
import { ImageProps } from "@/@Interfaces/ImageProps";
import { useDropzone } from "react-dropzone";
import "./style.css";
import { DeleteIcon, ImageIcon, RefreshIcon } from "@/constants/utils/svgs";
const Video: React.FC<ImageProps> = ({
  text,
  formSubmitted,
  required,
  imageType,
  onImageSelected,
  dropzoneType,
  value,
  setValue,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (imageType === "feed") {
      setSelectedFile(value);
    } else {
      setSelectedFile(value);
    }
  }, [value]);

  // useEffect(() => {
  //   if (formSubmitted && required && !selectedFile) {
  //     setError(`${text} required`);
  //   } else if (selectedFile) {
  //     onImageSelected(imageType, selectedFile);
  //     setError("");
  //   }
  // }, [required, text, formSubmitted, selectedFile]);

  const handleFileDelete = () => {
    setSelectedFile(null);
    setValue?.(null);
  };

  const validateFileName = (name: string) => {
    const maxLength = 100;
    const validPattern = /^[a-zA-Z0-9\- ()_]+$/;
    if (name.length > maxLength) {
      return `File name should not exceed ${maxLength} characters.`;
    }
    if (!validPattern.test(name)) {
      return "File name should only contain alphabets, numbers, and hyphens.";
    }
    return "";
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "video/mp4": [".mp4"],
      "video/webm": [".webm"],
      "video/ogg": [".ogg"],
      "video/avi": [".avi"],
      "video/mov": [".mov"],
      "video/wmv": [".wmv"],
      "video/flv": [".flv"],
      "video/3gpp": [".3gpp"],
      "video/quicktime": [".mov"],
      "video/x-msvideo": [".avi"],
      "video/x-flv": [".flv"],
      "video/x-ms-wmv": [".wmv"],
      "video/x-ms-asf": [".asf"],
      "video/x-matroska": [".mkv"],
      "video/x-avi": [".avi"],
    },
    onDrop: async (files) => {
      const file = files[0];
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      const fileNameError = validateFileName(fileNameWithoutExtension);
      if (fileNameError) {
        setError(fileNameError);
        return;
      }

      setSelectedFile(file);

      const video = document.createElement("video");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const videoObj = { path: reader.result, name: file.name };
        console.log(videoObj);
        setValue?.(videoObj);
        setError("");

        video.src = URL.createObjectURL(file);
      };
    },
  });

  return (
    <div className={dropzoneType ? "" : "image-dropzone-wrapper"} tabIndex={0}>
      {selectedFile ? (
        <div className="selected-file" tabIndex={0}>
          <div className="file-info row">
            <span className="input-file-name col-8 ">
              {ImageIcon()}{" "}
              {value && typeof value === "string"
                ? value.split("/")[value.split("/").length - 1]
                : selectedFile?.name?.length > 25
                ? selectedFile?.name?.slice(0, 25) + "..."
                : selectedFile?.name}
            </span>

            <label
              htmlFor={"video"}
              className="refresh-icon col-2"
              // onClick={(e) => {e.preventDefault()
              //   console.log("hello");

              // }}
            >
              {RefreshIcon()}
            </label>
            <input
              name={"abc"}
              id={"video" || "file-input"}
              {...getInputProps()}
            />
            <div className=" del-icon col-2" onClick={handleFileDelete}>
              <div>{DeleteIcon()}</div>
            </div>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`img-wrap ${
            imageType === "business" ? "business-image" : ""
          }`}
          tabIndex={0}
        >
          <input {...getInputProps()} />
          <div className="xtra d-flex justify-content-center">
            <h6
              className="header-img text-center"
              style={{ marginTop: dropzoneType ? "0px" : undefined }}
            >
              Drop Video here
            </h6>
            <p
              className="content-img"
              style={{ marginBottom: dropzoneType ? "0px" : undefined }}
            >
              Or <span className="click-here">click here</span> to browse your
              video
            </p>
          </div>
        </div>
      )}
      {/* {error && <span className="error-color">*{error}</span>} */}
    </div>
  );
};

export default Video;
