import React, { useState, useEffect } from "react";
import { ImageProps } from "@/@Interfaces/ImageProps";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setFeedImagePath } from "@/redux/slices/imageSlice";
import { setPageImagePath } from "@/redux/slices/imageSlice";
import { setBusinessImagePath } from "@/redux/slices/businessImageSlice";
// import "./style.css";
import { DeleteIcon, ImageIcon, RefreshIcon } from "@/constants/utils/svgs";
import showToast from "../cogo-toast";
const BusinessDocument: React.FC<any> = ({
  text,
  formSubmitted,
  required,
  imageSize,
  imageType,
  onImageSelected,
  dropzoneType,
  value,
  setValue,
  setRefreshBusinessImageError,
  editText,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [stepOneCompleted, setstepOneCompleted] = useState(false);
  const dispatch = useDispatch();

  const feedImage_1 = useSelector((state: any) => state.image_path.feedImage);

  const pageImage_1 = useSelector((state: any) => state.image_path.pageImage);
  const businessLogo = useSelector(
    (state: any) => state.business_image_path.businessImage
  );

  useEffect(() => {
    // if (imageType === "feed") {
    //   setSelectedFile(feedImage_1);
    // } else if (imageType === "page") {
    //   setSelectedFile(pageImage_1);
    // } else if (imageType === "business") {
    //   setSelectedFile(businessLogo);
    // }

    if (imageType === "feed") {
      // setSelectedFile(feedImage_1)
      setSelectedFile(value);
    } else {
      // setSelectedFile(pageImage_1)
      setSelectedFile(value);
    }
  }, [value]);

  // useEffect(() => {
  //    if(editText !== ''){
  //     console.log("no")
  //     setSelectedFile(null); // Update selectedFile when businessDocument changes
  //    }
  // }, [editText]);


  useEffect(() => {
    //when image is not selected error message appears
    if (formSubmitted && required && !selectedFile) {
      setError(`${text} required`);
    } else if (selectedFile) {
      onImageSelected(imageType, selectedFile);
      setError("");
      if (dropzoneType === "refresh") {
        setRefreshBusinessImageError("");
      }
    }
  }, [required, text, selectedFile]); //formsubmitted removed for time being

  const handleFileDelete = () => {
    setSelectedFile(null);
    setValue?.(null);
  };

  const validateFileName = (name: string) => {
    const maxLength = 100;
    const validPattern = /^[a-zA-Z0-9\- ()_]+$/;

    if (name.length > maxLength) {
      return `File name should not exceed ${maxLength} characters.`;
    }
    if (!validPattern.test(name)) {
      return "File name should only contain alphabets, numbers, and hyphens.";
    }
    return "";
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/pdf": [".pdf"],
    },
    onDrop: async (files) => {
      const file = files[0];
      const fileSizeInMB = file.size / (1024 * 1024); //converting into Mb
      // Check if image type is 'business' and file size is greater than 10MB(for business)
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      const fileNameError = validateFileName(fileNameWithoutExtension);
      if (fileNameError) {
        if (dropzoneType === "refresh") {
          setRefreshBusinessImageError(fileNameError);
        } else {
          setError(fileNameError);
        }

        return;
      }
      if (imageType === "business" && fileSizeInMB > 10) {
        if (dropzoneType === "refresh") {
          setRefreshBusinessImageError(
            "Image size should be 10MB or less for business document."
          );
        } else
          setError("Image size should be 10MB or less for business document.");
        return;
      }

      const reader = new FileReader();
      switch (file.type) {
        case "application/pdf":
          reader.readAsDataURL(file);
          break;
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          reader.readAsDataURL(file);
          break;
        case "text/plain":
          reader.readAsDataURL(file);
          break;
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          reader.readAsDataURL(file);
          break;
        default:
          showToast("Unsupported file type", "error");
      }
      reader.onload = () => {
        const fileData = reader.result;
        // Process or display file data
        setSelectedFile(file);
        const documentObj = { path: reader.result, name: file.name };
         setValue(documentObj)
        // documentObj ? setValue?.(documentObj) : setValue?.("");
    };
      //here pasted
    },
  });

  return (
    <div className={dropzoneType ? "" : "image-dropzone-wrapper"} tabIndex={0}>
      {dropzoneType == "refresh" ? (
        <div {...getRootProps()}>
          {RefreshIcon()}
          <input {...getInputProps()} />
        </div>
      ) : selectedFile ? (
        <div className="selected-file" tabIndex={0}>
          <div
            className="file-info-business d-flex row"
            style={{ padding: "0px", margin: "0px" }}
          >
            <div className="d-flex flex-row col-9 p-2">
              <div className="d-flex align-items-center justify-content-center b-img-icon">
                {ImageIcon()}
              </div>

              <div className="d-flex align-items-center justify-content-center p-1 text-truncate b-img-name">
                {selectedFile.name.length > 25
                  ? selectedFile.name.slice(0, 25) + "..."
                  : selectedFile.name}
              </div>
            </div>

            <div className=" b-del-icon col-3 " onClick={handleFileDelete}>
              <div>{DeleteIcon()}</div>
            </div>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`img-wrap ${
            imageType === "business" ? "business-image" : ""
          }`}
        >
          <input {...getInputProps()} />
          <div className="xtra d-flex justify-content-center">
            <h6
              className="b-header-img"
              style={{ marginTop: dropzoneType ? "0px" : undefined }}
            >
              Drop files here
            </h6>
            <p
              className="content-img"
              style={{ marginBottom: dropzoneType ? "0px" : undefined }}
            >
              Or to browse files from your computer&nbsp;
              <span className="click-here">click here</span>
            </p>
          </div>
        </div>
      )}
      {error && <span className="error-color aaa">*{error}</span>}
    </div>
  );
};

export default BusinessDocument;
