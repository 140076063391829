import React from "react";
import Select from "react-select";
import "./style.css";
import { SelectWrapperProps } from "@/@Interfaces/SelectWrapperProps";

const MultiSelectWrapper: React.FC<SelectWrapperProps> = ({
  value,
  options,
  placeholder,
  setValue,
  disabled
}) => {
  const handleChange = (selectedOptions: any) => {
    setValue(
      selectedOptions
        ? selectedOptions.map((option: any) => option.value)
        : null
    );
  };
  const formattedOptions = options.map((option: string) => ({
    value: option,
    label: option,
  }));

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? "none" : "#8E929A",
      boxShadow: state.isFocused ? "0 0 0 1px #BBA383" : "none",
      backgroundColor: "#1F1F22",
      minHeight: "48px",
      padding: "4px 8px 4px",
      borderRadius: "6px",
      "&:hover": {
        borderColor: "#8E929A",
      },
      "&:focus": {
        outline: "1px solid #BBA383",
        border: "none !important",
      },
    }),

    placeholder: (provided: any) => ({
      ...provided,
      color: "#999",
      fontSize: "12px",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#ffff",
      fontSize: "12px",
      backgroundColor: "#313237",
    }),
    menuPortal: (provided: any) => ({
      ...provided,
      marginleft: "10px",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      color: "white",
      fontSize: "12px",
      paddingLeft: "5px",
      paddingRight: "5px",
      backgroundColor: "#313237",
      borderRadius: "16px",
    }),

    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "white",
      fontSize: "12px",
      padding: "5px 0px",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      display: "flex",
      marginTop: "1.4px",
      alignItems: "center",
      color: "white",
      ":hover": {
        color: "white",
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#8E929A",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#BBA383" : "#1f1f22",
      color: state.isSelected ? "white" : "inherit",
      fontSize: "12px",
      "&:hover": {
        backgroundColor: "#BBA383",
        color: "white",
      },
    }),

    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#1F1F22",
      fontSize: "12px",
      color: "white",
      zIndex: 9999,
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    input: (provided: any) => ({
      ...provided,
      marginLeft: "2px",
      fontSize: "12px",
      border: "none !important",
      "& input": {
        border: "none !important",
      },
    }),
  };

  return (
    <Select
      value={
        value ? value.map((val: string) => ({ value: val, label: val })) : null
      }
      options={formattedOptions}
      onChange={handleChange}
      styles={customStyles}
      isMulti
      isDisabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default MultiSelectWrapper;
