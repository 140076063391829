import React, { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import "./style.css"
import { AuthRoutes, AuthType } from '@/constants/enum';

const getPath = (pathname:string)=>{
    return pathname===AuthRoutes.Linkedin?AuthType.Linkedin:AuthType.Insta
}

function OAuthResponse() {
    const [searchParams] = useSearchParams();
    const location = useLocation()

    useEffect(()=>{
        const tempCode = searchParams.get("code")
        const temperror = searchParams.get("error")

        if (tempCode) {
            if ((window as any).ReactNativeWebView) {
                (window as any).ReactNativeWebView.postMessage(JSON.stringify({code:tempCode,type:getPath(location.pathname)}));
              }
        }
        if (temperror) {
            if ((window as any).ReactNativeWebView) {
                (window as any).ReactNativeWebView.postMessage(JSON.stringify({error:temperror,type:getPath(location.pathname)}));
              }
        }

    },[searchParams])

  return (
    <div className='container'>
        <div>Redirecting...</div>
    </div>
  )
}

export default OAuthResponse