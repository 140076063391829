import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router";
import HomePage from "@components/HomePage";
import LogIn from "@components/SignIn";
import "./App.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import CreateCampaign from "./components/CreateCampaign";
import OAuthResponse from "./components/OAuthRedirect";
import { AuthRoutes } from "./constants/enum";
import CreateNewBusiness from "./components/CreateNewBusiness";
import CreateBenefit from "./components/CreateBenefit";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import PasswordChanged from "./components/PasswordChanged";
import usePolling from "./components/Notifications/notifications";
import ChangePassword from "./components/ChangePassword";
import ProfileSettings from "./components/ProfileSettings";

function App() {
  usePolling();
  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (
      !refreshToken &&
      window.location.pathname !== "/portal" &&
      window.location.pathname !== "/portal/reset-password"
    ) {
      window.location.href = "/portal";
    }
  }, []);

  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path="/portal" element={<LogIn />} />
            <Route
              path="/portal/forgot-password"
              element={<ForgotPassword />}
            />
            <Route path="/portal/reset-password" element={<ResetPassword />} />
            <Route
              path="/portal/password-changed"
              element={<PasswordChanged />}
            />
            <Route path="/home/:tab" element={<HomePage />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/profile-settings" element={<ProfileSettings/>} />
            <Route path="/create-event" element={<CreateCampaign />} />
            <Route path="/create-benefit" element={<CreateBenefit />} />
            <Route path="/create-business" element={<CreateNewBusiness />} />
            <Route index element={<Navigate to="/home/dashboard" replace />} />
            <Route
              path="/home"
              element={<Navigate to="/home/dashboard" replace />}
            />
            <Route path={AuthRoutes.Linkedin} element={<OAuthResponse />} />
            <Route path={AuthRoutes.Insta} element={<OAuthResponse />} />
          </Routes>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
