import React, { useEffect, useState } from "react";
import Button from "../../Button";
import Loader from "../../Spinner";
import showToast from "../../cogo-toast";
import "./style.css";
import { RecapModalProps } from "@/@Interfaces/ModalProps";
import { useDropzone } from "react-dropzone";
import { MdDeleteOutline } from "react-icons/md";
import { populateRecap, uplaodRecap } from "@/services/eventRecap";
interface IUploadImageTypes {
  type: string;
  url: string;
  _id: string;
}

const AddRecapModal: React.FC<RecapModalProps> = ({
  handleClose,
  viewEvent,
  eventId,
}) => {
  const [loading, setLoading] = useState(false);
  const [SelectedImages, setSelectedImages] = useState<any>([]);
  const [UploadedImages, setUploadedImages] = useState<IUploadImageTypes[]>([]);
  const [DeletedImages, setDeletedImages] = useState<string[]>([]);
  const [error, setError] = useState("");

  const handleUpdateRecap = async () => {
    setLoading(true);

    try {
      const response = await uplaodRecap(eventId, {
        images: SelectedImages,
        deleteImages: DeletedImages,
        oldImages: UploadedImages.map((image: IUploadImageTypes) => image.url),
      });
      handleClose();
    } catch (error: any) {
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      }
      if (error.response.data.code === 10037) {
        showToast(error.response.data.message, "error");
      } else {
        showToast(`Error Updating event. Please try again.`, "error");
      }
      setLoading(false);
    }
  };

  const validateFileName = (name: string) => {
    const maxLength = 100;
    const validPattern = /^[a-zA-Z0-9\- ()_]+$/;

    if (name.length > maxLength) {
      return `File name should not exceed ${maxLength} characters.`;
    }
    if (!validPattern.test(name)) {
      return "File name should only contain alphabets, numbers, and hyphens.";
    }
    return "";
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    onDrop: async (files, err, ev) => {
      if (files.length === 0) {
        return;
      }
      const Images: any = SelectedImages;

      for (const file of files) {
        const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
        const fileNameError = validateFileName(fileNameWithoutExtension);
        if (fileNameError) {
          setError(fileNameError);
          return;
        }
        const imageObj = { path: file, name: file.name };
        Images.push(file);
        setError("");
      }
      setTimeout(() => {
        setSelectedImages(Images);
      }, 0);
    },
    maxFiles: 10,
  });

  const getEventRecap = async () => {
    if (eventId) {
      const response = await populateRecap(eventId);
      if (response?.data?.media?.length > 0) {
        setUploadedImages(response.data.media);
      }
    }
  };

  useEffect(() => {
    if (eventId) {
      getEventRecap();
    }
  }, [eventId]);

  const handleDeleteClick = (
    id: number | string,
    type: "uploaded" | "selected"
  ) => {
    if (type === "uploaded") {
      setDeletedImages((prev: any[]) => [
        ...prev,
        UploadedImages.find((image: IUploadImageTypes) => image._id === id)
          ?.url,
      ]);
      setUploadedImages((prev: IUploadImageTypes[]) =>
        prev.filter((image: IUploadImageTypes) => image._id !== id)
      );
    } else {
      setSelectedImages((prev: any) =>
        prev.filter((__: any, index: number) => id !== index)
      );
    }
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "0 4.6vw",
        overflowY: "scroll",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="modelHeading-oi9">Event Recap Images</div>

      <div className="row mt-4">
        <div className="col-12 d-flex flex-column justify-content-start ">
          <div>
            <div {...getRootProps()} className={`img-wrap `} tabIndex={0}>
              <input {...getInputProps()} />
              <h6 className="header-img">Drop files here</h6>
              <p className="content-img">
                Or <span className="click-here">click here</span> to browse your
                files
              </p>
            </div>
          </div>
        </div>
        {SelectedImages.length > 0 || UploadedImages.length > 0 ? (
          <div className="image_container mt-4">
            {SelectedImages.map((image: any, index: number) => {
              return (
                <div key={image.path + index} className="image_wrapper">
                  <div
                    className="image_delete_icon_box rounded-circle"
                    onClick={() => handleDeleteClick(index, "selected")}
                  >
                    <MdDeleteOutline color="red" />
                  </div>
                  <img
                    className="selected-page-image1"
                    src={URL.createObjectURL(image)}
                    alt="Selected Feed Image"
                  />
                </div>
              );
            })}
            {UploadedImages.map((image: IUploadImageTypes) => {
              return (
                <div key={image._id} className="image_wrapper">
                  <div
                    className="image_delete_icon_box rounded-circle"
                    onClick={() => handleDeleteClick(image._id, "uploaded")}
                  >
                    <MdDeleteOutline color="red" />
                  </div>
                  <img
                    className="selected-page-image1"
                    src={image.url}
                    alt="Selected Feed Image"
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {error && <span className="error-color">*{error}</span>}

      <div className="row mt-4 mb-4">
        <div className="col-12 space">
          <div className="ticket-footer-wrapper">
            <div className="ticket-back">
              <Button
                title={viewEvent ? "EXIT" : "CANCEL"}
                type={"button"}
                backgroundColor="#1A1A1C"
                color="white"
                border="1px solid white"
                borderRadius="6px"
                padding="10px 48px"
                fontSize="12px"
                fontWeight="700"
                onButtonClick={handleClose}
              />
            </div>
            {!viewEvent && (
              <div className="ticket-finish">
                <button
                  type="submit"
                  onClick={handleUpdateRecap}
                  className="benefit-submit-button"
                  disabled={loading}
                >
                  {loading === true ? <Loader /> : "SUBMIT"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRecapModal;
