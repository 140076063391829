import URLS from '@/constants/api';
import { patch_2} from "./api";

export const updateMember = async (
    id: string | number,
    key: string,
    value: string,
  ) => {

    const requestData= {
        key: key,
        value: value,
      };

    const endpoint = `${URLS.UPDATE_MEMBERSHIP_TYPE}?id=${id}`;
    const response = await patch_2(endpoint, requestData);
    return response;
  };
  
