import { CampaignIcon, DashboardIcon, ExitIcon } from "@/constants/utils/svgs";
import { changePassword } from "@/services/changePassword";
import CryptoJS from "crypto-js";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import showToast from "../cogo-toast";
import Sidebar from "../Sidebar";
import Loader from "../Spinner";
import "./style.css";
import Modal from "../ExitModal";

const ChangePassword: React.FC = () => {
  const encryptionKey = process.env.REACT_APP_BASE_PRIVATE_KEY || "";

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const encryptString = (string: string, key: string) => {
    const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.AES.encrypt(string, keyUtf8, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  };

  const handleChangePassword = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    setFormSubmitted(true);

    if (newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }
    const encryptedString = encryptString(currentPassword, encryptionKey);
    const encryptedString2 = encryptString(newPassword, encryptionKey);
    const encryptedString3 = encryptString(confirmPassword, encryptionKey);
    const credentials = {
      oldPassword: encryptedString,
      newPassword: encryptedString2,
      repeatNewPassword: encryptedString3,
    };
    if (
      currentPassword &&
      newPassword &&
      confirmPassword &&
      !confirmPasswordError
    ) {
      try {
        const response = await changePassword(credentials);
        showToast("Password reset successfully", "success");
        navigate("/home/dashboard");
        setLoading(false);
      } catch (error: any) {
        showToast(error.response.data.message, "error");
        setLoading(false);
      }
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    navigate("/home/dashboard");
  };
  return (
    <section>
      <div>
        <div className="d-flex gap-2">
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <section className="change-password-section">
            <div className="row">
              <div className="col-lg-6 col-md-9 adjust-height-fhbsh">
                <div className="passsword-form-section ">
                  <div className="form-header">
                    <h2>Change your Password</h2>
                  </div>
                  <form className="reset-form-parent">
                    <div className="row mb-4">
                      <div className="d-flex flex-column col-12">
                        <label className="form-label">Current Password *</label>
                        <input
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          name="event name"
                          className="input-value-field"
                          type="password"
                          placeholder="Current Password"
                        />
                        {formSubmitted && !currentPassword && (
                          <div className="my-1 error-text-asd errorField">
                            *Current password required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="d-flex flex-column col-12">
                        <label className="form-label">New Password *</label>
                        <input
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          name="event name"
                          className="input-value-field"
                          type="password"
                          placeholder="New Password"
                        />
                        {formSubmitted && !newPassword && (
                          <div className="my-1 error-text-asd errorField">
                            *New password required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="d-flex flex-column col-12">
                        <label className="form-label">
                          Confirm New Password *
                        </label>
                        <input
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          name="event name"
                          className="input-value-field"
                          type="password"
                          placeholder="Confirm New Password"
                        />
                        {formSubmitted &&
                          !confirmPassword &&
                          !confirmPasswordError && (
                            <div className="my-1 error-text-asd errorField">
                              *Confirm password required
                            </div>
                          )}
                        {formSubmitted && confirmPasswordError && (
                          <div className="my-1 error-text-asd errorField">
                            {confirmPasswordError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mt-8">
                      <div className="col-6"></div>
                      <div className="col-6">
                        <div className="ticket-finish change-password">
                          <button
                            type="submit"
                            onClick={handleChangePassword}
                            className="benefit-submit-button"
                          >
                            {loading === true ? <Loader /> : "SUBMIT"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="form-image-right col-lg-6 col-md-3 p-0">
                <div className="image-section">
                  <div className="overlay"></div>
                  <span onClick={handleOpenModal} className="exit-icon">
                    {ExitIcon()}
                  </span>
                  <div className="campaign-icon">{CampaignIcon()}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Modal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleExit={handleExit}
        buttonTitle="EXIT"
        title="Are you sure you want to exit?"
        message="Are you sure you want to exit creating a new event? The information will not be saved."
      />
    </section>
  );
};

export default ChangePassword;
