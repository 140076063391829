export const benefitTableHeaders :any = [
    { name: "BENEFIT", style: { width: "16%" }, key: "name" },
    { name: "DESCRIPTION", style: { width: "17%" }, key: "description" },
    { name: "CATEGORY", style: { width: "7%" }, key: "category" },
    { name: "TYPE", style: { width: "8%" }, key: "type" },
    { name: "DATE/TIME", style: { width: "18%" }, key: "startDate" },
    { name: "ADDRESS", style: { width: "16%" }, key: "addressLine1" },
    { name: "STATUS", style: { width: "9%" }, key: "status" },
    { name: "BENEFIT URL", style: { width: "9%" }, key: "benefitUrl" },
  ];
  