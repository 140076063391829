import { TicketFormData } from "@/@Interfaces/CampaignProps";
import { event_available } from "@/constants/eventAvailable";
import { DashboardIcon } from "@/constants/utils/svgs";
import { createTicket } from "@/services/ticket";
import { createEventWithImage } from "@/services/updateEventImages";
import { uploadFile } from "@/services/uploadFiles";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import "react-toggle/style.css";
import showToast from "../cogo-toast";
import StepsTwo from "../EventDetails";
import Sidebar from "../Sidebar";
import StepsThree from "../TicketDetails";
import StepOne from "./stepone";
import "./style.css";
import { IStepOneFormDataType } from "@/@Interfaces/EventStepOneProps";
import { IStepTwoFormDataTypes } from "@/@Interfaces/EventStepTwoProps";
import moment from "moment";

const CreateCampaign: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState<number>(1);

  const [validationError, setValidationError] = useState<string | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);

  const [ticketAvailableOptions, setTicketAvailableOptions] =
    useState<string[]>(event_available);

  const [eventVideo, seteventVideo] = useState(null);

  const [stepOneFormData, setstepOneFormData] = useState<IStepOneFormDataType>({
    name: "",
    category: [],
    description: "",
    type: "",
    status: "",
    availableTo: [],
    classification: "",
    guestLimit: 0,
    externalSiteUrl: "",
    topEvent: false,
    feedImage: null,
    pageImage: null,
    accessEvent: false,
    videoUrl: null,
  });

  const [stepTwoFormData, setstepTwoFormData] = useState<IStepTwoFormDataTypes>(
    {
      eventDate: "",
      endDate: "",
      eventTime: "",
      timezone: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: "",
      longitudes: "",
      latitudes: "",
      postalCode: "",
      website: "",
      phoneNo: "",
      venueImage: null,
    }
  );

  const [timeRange, setTimeRange] = useState("");
  const [rawAddress, setRawAddress] = useState();
  const [ticketName, setTicketName] = useState<string>("");
  const [ticketPrice, setTicketPrice] = useState();
  const [ticketDesc, setTicketDesc] = useState<string>("");
  const [availableTo, setAvailableTo] = useState<string[]>([]);
  const [quantityAvailable, setQuantityAvailable] = useState();
  const [tickets, setTickets] = useState<TicketFormData["tickets"]>([
    {
      availableTo: "",
      name: "",
      price: "",
      description: "",
      quantityAvailable: "",
    },
  ]);

  const navigate = useNavigate();

  //create an event with tickets
  const handleSubmitCampaign = async () => {
    if (stepTwoFormData.eventDate && timeRange && !dateError) {
      try {
        let formattedStartDate, formattedEndDate;
        setLoading(true);
        if (stepTwoFormData.eventDate) {
          formattedStartDate = format(stepTwoFormData.eventDate, "MM-dd-yy");
        }
        if (stepTwoFormData.endDate) {
          formattedEndDate = format(stepTwoFormData.endDate, "MM-dd-yy");
        } else {
          formattedEndDate = format(stepTwoFormData.eventDate, "MM-dd-yy");
        }
        //creating a payload
        const eventData = {
          ///////// step one form data
          topEvent: stepOneFormData.topEvent,
          name: stepOneFormData.name,
          ...(stepOneFormData.guestLimit && {
            guestLimit: stepOneFormData.guestLimit,
          }),
          availableTo: stepOneFormData.availableTo,
          classification: stepOneFormData.classification,
          ...(stepOneFormData.classification === "External" &&
            stepOneFormData.externalSiteUrl !== "" && {
              externalSiteUrl: stepOneFormData.externalSiteUrl,
            }),
          description: stepOneFormData.description,
          status: stepOneFormData.status,
          category: stepOneFormData.category,
          type: stepOneFormData.type,
          accessEvent: stepOneFormData.accessEvent,

          /////// step two form data
          phoneNo: stepTwoFormData.phoneNo,
          website: stepTwoFormData.website,
          eventDate: moment(stepTwoFormData.eventDate).format("MM-DD-YY"),
          endDate: moment(stepTwoFormData?.endDate).format("MM-DD-YY"),

          timezone: stepTwoFormData.timezone.label,
          eventTime: stepTwoFormData.eventTime,
          ...(stepTwoFormData.addressLine1 !== "" && {
            addressLine1: stepTwoFormData.addressLine1,
          }),
          ...(stepTwoFormData.addressLine2 !== "" && {
            addressLine2: stepTwoFormData.addressLine2,
          }),
          ...(stepTwoFormData.city !== "" && { city: stepTwoFormData.city }),
          ...(stepTwoFormData.country !== "" && {
            country: stepTwoFormData.country,
          }),
          ...(stepTwoFormData.postalCode !== "" && {
            postalCode: stepTwoFormData.postalCode,
          }),
          ...(stepTwoFormData.latitudes !== null && {
            latitudes: stepTwoFormData.latitudes,
          }),
          ...(stepTwoFormData.longitudes !== null && {
            longitudes: stepTwoFormData.longitudes,
          }),
        };

        //create a ticket payload
        const ticketData = tickets.map((ticket, index) => {
          return {
            name: ticket?.name,
            description: ticket.description,
            availableTo: ticket.availableTo,
            price: parseFloat(ticket.price) || 0,
            quantityAvailable: parseInt(ticket.quantityAvailable) || 0,
          };
        });

        const combinedData = {
          event: eventData,
          ...(ticketData.length > 0 &&
            ticketData[0].name !== "" &&
            ticketData[0].price !== 0 &&
            ticketData[0].quantityAvailable !== 0 && {
              tickets: ticketData,
            }),
        };
        const apiResponse = await createTicket(combinedData);
        const eventId = apiResponse.data.eventId;
        const uploadFiles = {
          type: "Events",
          id: eventId,
          feedImage: stepOneFormData.feedImage,
          pageImage: stepOneFormData.pageImage,
          videoUrl: stepOneFormData.videoUrl,
          venueImage: stepTwoFormData.venueImage,
        };
        //upload images to cloud front
        const upload = await uploadFile(uploadFiles);
        //update event with images url
        const resp = await createEventWithImage(
          apiResponse.data.eventId,
          upload[0],
          upload[1],
          upload[0],
          uploadFiles.videoUrl ? upload[2] : null,
          uploadFiles.venueImage && uploadFiles.videoUrl
            ? upload[3]
            : uploadFiles.venueImage
            ? upload[2]
            : null
        );
        setLoading(false);
        navigate("/home/events");
        showToast("Event created successfully!", "success");
      } catch (err: any) {
        setLoading(false);
        if (err.message === "Network Error") {
          showToast(
            "It seems you're offline. Please check your connection and try again.",
            "error"
          );
        } else {
          showToast("Something went wrong. Please try again.", "error");
        }
      }
    }
  };

  return (
    <div>
      {steps === 1 && (
        <div className="d-flex gap-2">
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <StepOne
            setSteps={setSteps}
            seteventVideo={seteventVideo}
            eventVideo={eventVideo}
            setstepOneFormData={setstepOneFormData}
            stepOneFormData={stepOneFormData}
          />
        </div>
      )}

      {steps === 2 && (
        <div className="d-flex gap-2 w-100">
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <StepsTwo
            setstepTwoFormData={setstepTwoFormData}
            stepTwoFormData={stepTwoFormData}
            eventTypeOption={stepOneFormData.type}
            loading={loading}
            setSteps={setSteps}
            setTimeRange={setTimeRange}
            rawAddress={rawAddress}
            setRawAddress={setRawAddress}
            handleSubmitCampaign={handleSubmitCampaign}
            validationError={validationError}
            setValidationError={setValidationError}
            dateError={dateError}
            setDateError={setDateError}
          />
        </div>
      )}

      {steps > 2 && (
        <div className="d-flex gap-2 w-100">
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <StepsThree
            loading={loading}
            setLoading={setLoading}
            steps={steps}
            setSteps={setSteps}
            ticketName={ticketName}
            setTicketName={setTicketName}
            ticketPrice={ticketPrice}
            setTicketPrice={setTicketPrice}
            ticketDesc={ticketDesc}
            setTicketDesc={setTicketDesc}
            availableTo={availableTo}
            setAvailableTo={setAvailableTo}
            quantityAvailable={quantityAvailable}
            setQuantityAvailable={setQuantityAvailable}
            tickets={tickets}
            setTickets={setTickets}
            handleSubmitCampaign={handleSubmitCampaign}
            ticketAvailableOptions={ticketAvailableOptions}
            setTicketAvailableOptions={setTicketAvailableOptions}
            eventAvailableTo={stepOneFormData.availableTo}
          />
        </div>
      )}
    </div>
  );
};

export default CreateCampaign;

//create an event if type is guestlist
// const handleSubmitCampaign2 = async () => {
// if (selectedStartDate && timeRange && !dateError) {
// try {
// setLoading(true);
// let formattedStartDate, formattedEndDate;
// if (selectedStartDate) {
//   formattedStartDate = format(selectedStartDate, "MM-dd-yy");
// }
// if (selectedEndDate === null) {
//   formattedEndDate = formattedStartDate;
// }
// if (selectedEndDate) {
//   formattedEndDate = format(selectedEndDate, "MM-dd-yy");
// }
//payload
// const eventData = {
//   topEvent: isToggled,
//   name: eventName,
//   guestLimit: guestCount,
//   availableTo: eventAvailableTo,
//   classification: classification,
//   ...(classification === "External" &&
//     externalUrl !== "" && { externalSiteUrl: externalUrl }),
//   description: campaignDescription,
//   eventDate: formattedStartDate,
//   endDate: formattedEndDate,
//   timezone: selectedTimezone.label,
//   eventTime: timeRange,
//   ...(locationInfo.addressLine1 !== "" && {
//     addressLine1: locationInfo.addressLine1,
//   }),
//   ...(locationInfo.addressLine2 !== "" && {
//     addressLine2: locationInfo.addressLine2,
//   }),
//   ...(locationInfo.city !== "" && { city: locationInfo.city }),
//   ...(locationInfo.country !== "" && { country: locationInfo.country }),
//   ...(locationInfo.postalCode !== "" && {
//     postalCode: locationInfo.postalCode,
//   }),
//   ...(locationInfo.latitudes !== null && {
//     latitudes: locationInfo.latitudes,
//   }),
//   ...(locationInfo.longitudes !== null && {
//     longitudes: locationInfo.longitudes,
//   }),
//   status: select,
//   category: categoriesOption,
//   type: eventTypeOption,
// };
// const combinedData = {
//   event: eventData,
// };
// const apiResponse = await createTicket(combinedData);
// const eventId = apiResponse.data.eventId;
//upload image on cloud front
// const uploadFiles = {
//   type: "Events",
//   id: eventId,
//   feedImage: feedImage,
//   pageImage: pageImage,
// };
// const upload = await uploadFile(uploadFiles);
//update the event with images url
// const resp = await createEventWithImage(
//   apiResponse.data.eventId,
//   upload[0],
//   upload[1],
//   upload[0]
// );

// setLoading(false);
// navigate("/home/events");
// showToast("Event created successfully!", "success");
// } catch (err: any) {
// setLoading(false);
// if (err.message === "Network Error") {
// showToast(
// "It seems you're offline. Please check your connection and try again.",
// "error"
// );
// } else {
// showToast("Something went wrong. Please try again.", "error");
// }
// }
// }
// };
