import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TicketState {
  tickets: Array<[]>; 
}

const initialState: TicketState = {
  tickets: [],
};

const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    addTicket: (state, action: PayloadAction<any>) => {
      state.tickets.push(action.payload);
    }
  },
});

export const { addTicket} = ticketSlice.actions;
export default ticketSlice.reducer;
