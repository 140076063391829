import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";
import "react-data-grid/lib/styles.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { DownloadIcon } from "@constants/utils/svgs";
import { EventType, RSVPTableRow } from "@/@Interfaces/TableProps";
import { useSelector } from "react-redux";
import Loader from "../Spinner";
import { options } from "@constants/options";
import TableFooter from "../TableFooter";
import { downloadRSVPAPI } from "@/services/downloadRSVP";
import { RSVPTableHeaders } from "@/constants/RSVPTableHeaders";
import showToast from "../cogo-toast";
import { RSVPEvents, RSVPTabProps } from "@/@Interfaces/RSVPTabProps";
import { getAllEvents } from "@/services/getAllEvents";
import { getAllAtendees } from "@/services/attendees";
import EventDropdown from "../EventDropdown";
import { getAllTicketedAttendees } from "@/services/ticketedAttendees";

const RSVPTab: React.FC<RSVPTabProps> = ({ rsvpEventID }) => {
  console.log(rsvpEventID);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [tableData, setTableData] = useState<RSVPTableRow[]>([]);
  const [sortOn, setSortOn] = useState<string>("user.firstName");
  const [sortBy, setSortBy] = useState<"asc" | "desc">("asc");
  const [formattedEvents, setFormattedEvents] = useState<RSVPEvents[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<{
    name: string;
    id: string;
  }>({ name: "", id: "" });
  const [eventType, setEventType] = useState<string>("");

  const latestDate = new Date();
  const formattedLatestDate = latestDate.toLocaleString();

  const dropdownRef2 = useRef<HTMLDivElement>(null);

  const dropdownOption = useSelector(
    (state: any) => state.applicant.dropdownOption
  );

  const pageLimit = options.includes(dropdownOption) ? dropdownOption : 25;

  const applicantStatus = useSelector(
    (state: any) => state.applicant_status.applicantStatus
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [dropdownOption]);

  useEffect(() => {
    getAllRsvpEvents();
  }, []);

  useEffect(() => {
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;

    if (eventType === "Guest List" || rsvpEventID?.type === "Guest List") {
      fetchData(
        currentPage,
        pl,
        "",
        "firstName",
        sortBy,
        (selectedEvent.id as string) || (rsvpEventID?.id as string)
      );
    } else if (eventType === "Ticketed" || rsvpEventID?.type === "Ticketed") {
      fetchTicketedData(
        currentPage,
        pl,
        "",
        sortOn,
        sortBy,
        (selectedEvent.id as string) || (rsvpEventID?.id as string)
      ); //previous current page is 1
    }
  }, [applicantStatus, dropdownOption, sortOn, sortBy]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;
    if (eventType === "Guest List") {
      fetchData(pageNumber, pl, "", "", "", selectedEvent.id);
    } else if (eventType === "Ticketed") {
      fetchTicketedData(pageNumber, pl, "", "", "", selectedEvent.id);
    }
  };

  //previous page
  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  //next page
  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };
  //get rsvp ticketted and guest-list-data
  const fetchData = async (
    pageNumber = 1,
    pageLimit: number,
    search = "",
    sortOn = "",
    sortBy = "",
    eventId: string
  ) => {
    try {
      setShowSpinner(true);
      setTableData([]);
      const response = await getAllAtendees(
        pageNumber,
        pageLimit,
        search,
        sortOn,
        sortBy,
        eventId
      );
      setTableData(response.data.events.docs);
      setTotalRecords(response.data.totalDocs);
      setTotalPages(response.data.totalPages);
    } catch (error: any) {
      setShowSpinner(false);
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast("Something went wrong.", "error");
      }
    }
    setShowSpinner(false);
  };

  const fetchTicketedData = async (
    pageNumber = 1,
    pageLimit: number,
    search = "",
    sortOn = "",
    sortBy = "",
    eventId: string
  ) => {
    try {
      setShowSpinner(true);
      setTableData([]);
      const response = await getAllTicketedAttendees(
        pageNumber,
        pageLimit,
        search,
        sortOn,
        sortBy,
        eventId
      );

      const transformedData = response.data.purchasedTickets.map(
        (event: any) => ({
          _id: event.eventId,
          firstName: event.user.firstName,
          userId: event.user._id,
          lastName: event.user.lastName,
          email: event.user.email,
          instagram: event.user.instagram,
          dateCreated: event.dateCreated,
          channel: event.user.channel,
        })
      );
      setTableData(transformedData);
      setTotalRecords(response.data.totalDocs);
      setTotalPages(response.data.totalPages);
    } catch (error: any) {
      setShowSpinner(false);
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast("Something went wrong.", "error");
      }
    }
    setShowSpinner(false);
  };

  // useEffect(() => {
  //   if (selectedEvent.name && eventType === "Guest List" && selectedEvent.id) {
  //     fetchData(currentPage, pageLimit, "", "", "", selectedEvent.id);
  //   } else if (eventType === "Ticketed" && selectedEvent.id) {
  //     fetchTicketedData(currentPage, pageLimit, "", "", "", selectedEvent.id);
  //   }
  // }, []);

  //email user
  const handleEmailClick = (email: string) => {
    const mailtoLink = `mailto:${email}`;
    const anchor = document.createElement("a");
    anchor.href = mailtoLink;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  //sorting
  const handleHeaderClick = (headerKey: string) => {
    setCurrentPage(1);
    setSortBy((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const formatDateString = (dateString: string): string => {
    const dateTime = new Date(dateString);
    return dateTime.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  //searching
  const handleSearchRSVP = (value: string) => {
    if (value.length >= 3 || value === "") {
      setCurrentPage(1);
      if (eventType === "Guest List") {
        fetchData(1, pageLimit, value, "", "", selectedEvent.id);
      } else if (eventType === "Ticketed") {
        fetchTicketedData(1, pageLimit, value, "", "", selectedEvent.id);
      }
    }
  };
  const handleSubmit2 = (e: React.FormEvent) => {
    e.preventDefault();
  };

  //download csv file
  const downloadRSVPFile = async () => {
    try {
      setShowSpinner(true);
      const response = await downloadRSVPAPI(selectedEvent.id, eventType);
      const url = window.URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      a.download = `PM_Attendees_${formattedLatestDate}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      showToast("Error in downloading file.", "error");
    } finally {
      setShowSpinner(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  //Set Object in attendees Dropdown

  const setObjectAttendess = (formattedEventsData: any) => {
    const typeName = formattedEventsData.value.split(" - ")[0]; // Extract type
    setEventType(typeName);
    setSelectedEvent({
      name: formattedEventsData.value,
      id: formattedEventsData.id,
    });
    return formattedEventsData;
  };
  // Function to fetch all RSVP events and set default selection
  const getAllRsvpEvents = async () => {
    try {
      // Fetching all events from the API
      const response = await getAllEvents();
      const eventsData: EventType[] = response.data.events;

      // Formatting the events data for the dropdown
      const formattedEventsData = eventsData.map((event: any) => ({
        value: `${event.type} - ${event.name}`,
        id: event._id,
        label: `${event.type} - ${event.name}`,
      }));

      // Setting the formatted events to state
      setFormattedEvents(formattedEventsData);

      // Check if there are events and set the first one as the selected event by default
      if (formattedEventsData.length > 0) {
        let firstEvent;
        let isFound = false;
        for (const a in formattedEventsData) {
          if (formattedEventsData[a].id == rsvpEventID?.id) {
            firstEvent = setObjectAttendess(formattedEventsData[a]);
            isFound = true;
            break;
          }
        }
        if (
          rsvpEventID &&
          rsvpEventID?.id !== "" &&
          rsvpEventID?.type !== "" &&
          rsvpEventID?.name !== "" &&
          !isFound
        ) {
          setSelectedEvent({ name: rsvpEventID?.name, id: rsvpEventID?.id });
          setEventType(rsvpEventID?.type);
        } else if (!isFound) {
          firstEvent = setObjectAttendess(formattedEventsData[0]);
        }

        // // Fetch data immediately for the auto-selected event
        // if (eventType === "Guest List" && selectedEvent.id) {
        //   fetchData(1, pageLimit, "", "", "", firstEvent.id);
        // } else if (eventType === "Ticketed" && selectedEvent.id) {
        //   fetchTicketedData(1, pageLimit, "", "", "", firstEvent.id);
        // }
      }
    } catch (error) {
      // Show error message if fetching events fails
      showToast("Error in fetching Events", "error");
    }
  };

  const handleEventSelection = (name: string, id: string) => {
    const typeName = name.split(" - ")[0];
    setEventType(typeName);
    setSelectedEvent({ name, id });
    if (typeName === "Guest List" && id) {
      fetchData(1, pageLimit, "", "", "", id);
    }
    if (typeName === "Ticketed" && id) {
      fetchTicketedData(1, pageLimit, "", "", "", id);
    }
  };

  return (
    <section className="table-section d-flex flex-column justify-content-between gap-4 pb-2">
      <div className="table-header d-flex flex-1 align-items-center flex-wrap justify-content-between">
        <h4 className="t-head">Attendees</h4>
        <div className="search-bar d-flex flex-wrap gap-4">
          <div>
            <form data-testid="search-form" onSubmit={handleSubmit2}>
              <div className="search-content-sb">
                <FontAwesomeIcon
                  className="search-icon"
                  icon={faSearch}
                  style={{}}
                />
                <input
                  style={{ height: "40px" }}
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    const { value } = e.target;
                    handleSearchRSVP(value);
                  }}
                />
              </div>
              <button type="submit" style={{ display: "none" }}>
                Search
              </button>
            </form>
          </div>

          <div className="country all-events-dropdown">
            <EventDropdown
              options={formattedEvents}
              value={selectedEvent.name}
              setValue={handleEventSelection}
              placeholder="Choose an event"
            />
          </div>

          <div className="download-icon" onClick={toggleDropdown}>
            {DownloadIcon()}
          </div>
        </div>
      </div>
      {showDropdown && (
        <div
          id="dropdown"
          className="csv-dropdown d-flex flex-column justify-content-center"
          ref={dropdownRef2}
        >
          <div onClick={downloadRSVPFile}>Export as .CSV</div>
          <div className="pdf-container">Export as .PDF</div>
        </div>
      )}
      <div className="row">
        <div className="col-8"></div>
      </div>
      <div className="table-container flex-grow">
        <table
          id="table"
          className="table table-hover"
          style={{ backgroundColor: "#1A1A1C" }}
        >
          <thead className="t-header">
            <tr>
              {RSVPTableHeaders.map((header) => (
                <th
                  className="t-columns"
                  key={header.key}
                  scope="col"
                  style={header.style}
                  onClick={() => handleHeaderClick(header.key)}
                >
                  {header.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="t-body">
            {!showSpinner && tableData.length === 0 && searchTerm.length > 0 ? (
              <td colSpan={RSVPTableHeaders.length + 1}>
                <div className="no-records-found">No records found.</div>
              </td>
            ) : !showSpinner &&
              tableData.length === 0 &&
              searchTerm.length === 0 ? (
              <td colSpan={RSVPTableHeaders.length + 1}>
                <div className="no-records-found">
                  There are currently no attendees in this Event.
                </div>
              </td>
            ) : (
              <>
                {tableData.map((rowData, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      {RSVPTableHeaders.map((header) => (
                        <td
                          style={{ verticalAlign: "middle" }}
                          key={header.key}
                        >
                          {header.key === "firstName" ? (
                            <>
                              {rowData.firstName} {rowData.lastName}{" "}
                            </>
                          ) : header.key === "email" ? (
                            <div className="">
                              <span
                                className="email-rsvp"
                                onClick={() => handleEmailClick(rowData.email)}
                                style={{ fontWeight: 400 }}
                              >
                                {rowData.email}
                              </span>
                            </div>
                          ) : header.key === "instagram" ? (
                            <a
                              className="linkedin-url"
                              href={`https://www.instagram.com/${rowData.instagram}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              <div className="">
                                <span>{rowData.instagram}</span>
                              </div>
                            </a>
                          ) : header.key === "eventName" ? (
                            <div className="">
                              <span>{rowData.eventName}</span>
                            </div>
                          ) : header.key === "createdDate" ? (
                            <div className="">
                              <span>
                                {" "}
                                {formatDateString(
                                  rowData.createdDate ??
                                    rowData.dateCreated ??
                                    ""
                                )}
                              </span>
                            </div>
                          ) : header.key === "channel" ? (
                            <div className="">
                              <span>{rowData.channel}</span>
                            </div>
                          ) : (
                            rowData[header.key as keyof typeof rowData]
                          )}
                        </td>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              </>
            )}
          </tbody>
        </table>
        {showSpinner && (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </div>

      <TableFooter
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        handlePrev={handlePrev}
        handleNext={handleNext}
        totalPages={totalPages}
        currentPage={currentPage}
      />
    </section>
  );
};

export default RSVPTab;
