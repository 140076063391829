import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businessImage: "",
};

const businessImageSlice = createSlice({
  name: "business_image_path",
  initialState,
  reducers: {
    setBusinessImagePath: (state, action) => {
      state.businessImage = action.payload;
    },
    resetBusinessImage: (state) => {
      state.businessImage = "";
    },
  },
});

export const { setBusinessImagePath, resetBusinessImage } = businessImageSlice.actions;
export default businessImageSlice.reducer;
