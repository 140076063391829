import { get_3 } from "./api";
import URLS from "../constants/api";

export const getEnquiries = async (
  page: number,
  limit: number,
  // searchOn: string,
  search: string,
  sortOrder: string,
  sortBy: string
) => {
  const requestData = {
    params: {
      page,
      limit,
      // ...(searchOn !== "" && { searchOn }),
      ...(search !== "" && { search }),
      ...(sortOrder !== "" && { sortOrder }),
      ...(sortBy !== "" && { sortBy }),
    },
  };
  const response = await get_3(URLS.GET_ENQUIRIES, requestData);

  return response;
};
