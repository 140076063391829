import { BenefitFormData } from "@/@Interfaces/BenefitFormProps";
import { DashboardIcon } from "@/constants/utils/svgs";
import { createBenefits } from "@/services/createBenefits";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import "react-toggle/style.css";
import showToast from "../cogo-toast";
import BenefitStepsTwo from "../CreateBenefit2";
import Sidebar from "../Sidebar";
import BenefitStepOne from "./benefit";
import "./style.css";

const CreateBenefit: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState<number>(1);
  const [select, setSelect] = useState<string>("");
  const [categoriesOption, setCategoriesOption] = useState<string>("");
  const [isOnline, setIsOnline] = useState("");
  const [benefitTypeOption, setBenefitTypeOption] = useState<string>("");
  const [benefitName, setBenefitName] = useState<string>("");
  const [isToggled, setIsToggled] = useState(false);
  const [urlError, setUrlError] = useState("");
  const [benefitAvailableTo, setBenefitAvailableTo] = useState("");
  const [benefitDescription, setBenefitDescription] = useState<string>("");
  const [feedImage, setFeedImage] = useState(null);
  const [pageImage, setPageImage] = useState(null);
  const [locationInfo, setLocationInfo] = useState<BenefitFormData>({
    benefitDate: null,
    country: "",
    benefitUrl: "",
    city: "",
    address: "",
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    latitudes: null,
    longitudes: null,
    coordinates: "",
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [rawAddress, setRawAddress] = useState();
  const navigate = useNavigate();

  //Create a benefit function
  const handleSubmitCampaign = async () => {
    try {
      setLoading(true);
      let formattedStartDate, formattedEndDate;
      if (selectedStartDate) {
        formattedStartDate = format(selectedStartDate, "MM-dd-yy");
      }
      if (selectedEndDate === null || undefined) {
        formattedEndDate = formattedStartDate;
      }
      if (selectedEndDate === null) {
        setSelectedEndDate(selectedStartDate);
      }
      if (selectedEndDate) {
        formattedEndDate = format(selectedEndDate, "MM-dd-yy");
      }
      const benefitData =
        isOnline === "Yes"
          ? {
              displayOnAccess: isToggled,
              name: benefitName,
              status: select,
              startDate: formattedStartDate,
              availableTo: benefitAvailableTo,
              endDate: formattedEndDate,
              description: benefitDescription,
              category: categoriesOption,
              type: benefitTypeOption,
              benefitUrl: locationInfo.benefitUrl,
              online: true,
            }
          : {
              displayOnAccess: isToggled,
              name: benefitName,
              description: benefitDescription,
              startDate: formattedStartDate,
              availableTo: benefitAvailableTo,
              endDate: formattedEndDate,
              online: false,
              addressLine1: locationInfo?.addressLine1 || "",
              addressLine2: locationInfo?.addressLine2 || "",
              city: locationInfo?.city || "",
              country: locationInfo?.country || "",
              postalCode: locationInfo?.postalCode || "",
              latitudes: locationInfo?.latitudes || null,
              longitudes: locationInfo?.longitudes || null,
              status: select,
              category: categoriesOption,
              type: benefitTypeOption,
            };

      const isEventDataValid = Object.entries(benefitData).every(
        ([key, value]) => {
          if (key === "addressLine2") {
            return true;
          }
          return value !== null && value !== "";
        }
      );

      if (isEventDataValid && urlError === "") {
        const apiResponse = await createBenefits(
          benefitData,
          feedImage,
          pageImage
        );
        setLoading(false);
        navigate("/home/benefits");
        showToast("Benefit created successfully!", "success");
      } else {
        showToast(
          "Benefit data contains null or empty values. Cannot update.",
          "error"
        );
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showToast(err.message, "error");
    }
  };

  return (
    <div>
      {steps === 1 && (
        <div className="d-flex gap-2">
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <BenefitStepOne
            isToggled={isToggled}
            setIsToggled={setIsToggled}
            steps={steps}
            setSteps={setSteps}
            select={select}
            setSelect={setSelect}
            categoriesOption={categoriesOption}
            setCategoriesOption={setCategoriesOption}
            benefitTypeOption={benefitTypeOption}
            setBenefitTypeOption={setBenefitTypeOption}
            benefitName={benefitName}
            setBenefitName={setBenefitName}
            benefitDescription={benefitDescription}
            setBenefitDescription={setBenefitDescription}
            feedImage={feedImage}
            setFeedImage={setFeedImage}
            pageImage={pageImage}
            setPageImage={setPageImage}
            availableTo={benefitAvailableTo}
            setAvailableTo={setBenefitAvailableTo}
          />
        </div>
      )}
      {steps === 2 && (
        <div className="d-flex gap-2 w-100">
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <BenefitStepsTwo
            steps={steps}
            loading={loading}
            setLoading={setLoading}
            setSteps={setSteps}
            isOnline={isOnline}
            setIsOnline={setIsOnline}
            locationInfo={locationInfo}
            setLocationInfo={setLocationInfo}
            urlError={urlError}
            setUrlError={setUrlError}
            selectedDate={selectedDate}
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            setSelectedDate={setSelectedDate}
            rawAddress={rawAddress}
            setRawAddress={setRawAddress}
            handleSubmitCampaign={handleSubmitCampaign}
          />
        </div>
      )}
    </div>
  );
};

export default CreateBenefit;
