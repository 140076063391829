import React from "react";
import "./style.css";
import CustomSelect from "../Dropdown";
import { options } from "@constants/options";
import {
  ArrowIcon,
  ArrowNext,
  NextArrow,
  PrevIcon,
} from "@/constants/utils/svgs";

const TableFooter: React.FC<{
  totalPages: number;
  currentPage: number;
  totalRecords: number;
  onPageChange: (pageNumber: number) => void;
  handlePrev: () => void;
  handleNext: () => void;
}> = ({
  totalPages,
  currentPage,
  totalRecords,
  onPageChange,
  handlePrev,
  handleNext,
}) => {
  const getPageNumbers = (): number[] => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };

  const renderPageNumbers = () => {
    const pageNumbers = getPageNumbers();

    if (totalPages <= 7) {
      return pageNumbers.map((page) => (
        <span
          key={page}
          onClick={() => onPageChange(page)}
          style={{
            cursor: "pointer",
            backgroundColor: page === currentPage ? "#BBA383" : "#1A1A1C",
            color: "white",
            borderRadius: "6px",
            padding: "5px 10px",
          }}
        >
          {page}
        </span>
      ));
    } else {
      return (
        <>
          {pageNumbers
            .filter(
              (page) => page >= currentPage - 2 && page <= currentPage + 2
            )
            .map((page) => (
              <span
                key={page}
                onClick={() => onPageChange(page)}
                style={{
                  cursor: "pointer",
                  backgroundColor: page === currentPage ? "#BBA383" : "#1A1A1C",
                  color: "white",
                  borderRadius: "6px",
                  padding: "5px 10px",
                }}
              >
                {page}
              </span>
            ))}
          {currentPage < totalPages - 3 && <span>...</span>}
          {currentPage < totalPages - 3 && (
            <span onClick={() => onPageChange(totalPages)}>{totalPages}</span>
          )}
        </>
      );
    }
  };

  return (
    <div className="table-footer d-flex justify-content-between align-items-center flex-wrap">
      <div className="total-number">
        Total:&nbsp;&nbsp;<span className="records">{totalRecords}</span>
      </div>

      <div className="d-flex align-items-center footer__items flex-wrap justify-content-start gap-4">
        <div className="d-flex flex-wrap gap-2 align-items-center ">
          <div className="result-wrapper">
            <span>Results per Page</span>
          </div>
          <div className="options-wrapper">
            <CustomSelect
              options={options}
              width="100px"
              color="#8e929a"
              backgroundColor="##313237"
              text="25"
            />
          </div>
        </div>
        <div className=" d-flex flex-wrap justify-content-center align-items-center gap-3 p-1 ">
          <>
            {currentPage != 1 && (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => onPageChange(1)}
              >
                {PrevIcon()}
              </span>
            )}
            {currentPage != 1 && (
              <span
                style={{ cursor: "pointer" }}
                id="prev"
                onClick={handlePrev}
              >
                {ArrowNext()}
              </span>
            )}
          </>

          {renderPageNumbers()}
          {currentPage < totalPages && (
            <span style={{ cursor: "pointer" }} id="next" onClick={handleNext}>
              {NextArrow()}
            </span>
          )}
          {currentPage < totalPages && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => onPageChange(totalPages)}
            >
              {ArrowIcon()}
            </span>
          )}
        </div>
        <div className="page-no">
          <span>
            Page {currentPage} of {totalPages}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
