type TableColumn = {
  name: string;
  style: {
    width: string;
    textAlign?: "left" | "right" | "center" | "justify" | "initial" | "inherit";
  };
  key: string;
};

export const businessTableHeaders: TableColumn[] = [
  { name: "BUSINESS", style: { width: "15%" }, key: "businessName" },
  { name: "CATEGORY", style: { width: "10%" }, key: "businessCategory" },
  { name: "WEBSITE", style: { width: "15%" }, key: "businessWebsite" },
  { name: "PHONE NUMBER", style: { width: "10%" }, key: "phoneNumber" },
  { name: "ACCOUNT MANAGER", style: { width: "12%" }, key: "accountManager" },
  { name: "CITY", style: { width: "8%" }, key: "city" },
  { name: "ADDRESS", style: { width: "14%" }, key: "addressLine1" },
  { name: "INSTAGRAM", style: { width: "8%" }, key: "instagram" },
  { name: "LINKEDIN", style: { width: "8%" }, key: "linkedin" },
];
