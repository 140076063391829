import { DocumentFormData } from '@/@Interfaces/BusinessStepFourProps';
import { uploadEditDocuments} from './api';
import URLS from '@/constants/api';

export const reUploadDocumentFile = async (documents: DocumentFormData[], businessId: string | undefined) => { 

    const endpoint = `${URLS.UPLOAD_BULK_DOCUMENTS}/${businessId}/upload-docs-bulk`;
    const response = await uploadEditDocuments(endpoint, documents);
    return response;
};
