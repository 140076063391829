
import { combineReducers } from '@reduxjs/toolkit'
import applicantSlice from './applicantSlice'
import sidebarSlice from './sidebarSlice'
import campaignSlice from './campaignSlice'
import businessSlice from './businessSlice'
import imageSlice from './imageSlice'
import businessImageSlice from './businessImageSlice'
import locationSlice from './locationSlice'
import ticketSlice from './ticketSlice'
import accountManagerSlice from './accountManagerSlice'

const rootReducer = combineReducers({
  applicant: applicantSlice,
  applicant_status: sidebarSlice,
  campaign_form: campaignSlice,
  business_form: businessSlice,
  image_path: imageSlice,
  business_image_path: businessImageSlice,
  location:locationSlice,
  ticket: ticketSlice,
  account_manager_details: accountManagerSlice
  
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
